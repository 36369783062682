import type { Rule, RulePrivate } from './rule'

export type ComparisonOperator = '=' | '!=' | '>' | '<' | '>=' | '<='

const isBoolean = (stringValue: string): boolean =>
  stringValue.toLowerCase() === 'true' || stringValue.toLowerCase() === 'false'

const evaluateBoolean = (stringValue: string): boolean => stringValue.toLowerCase() === 'true'

const isNumber = (stringValue: string): boolean => !isNaN(+stringValue)

const evaluateNumber = (stringValue: string): number => +stringValue

const evaluateString = (stringValue: string): string => {
  const stringValueRegExp = /^(['"])(.*)(\1)$/gm
  return stringValue.replace(stringValueRegExp, (a, b, value) => value)
}

export type RuleValue = string | boolean | number
export type ValueResolver = (name: string) => RuleValue
export class NameRule implements Rule, RulePrivate {
  public static createFromString(str: string, valueResolver: ValueResolver): NameRule {
    const nameRuleExp = /^([a-zA-Z0-9.\-_]*)( *)(>|<|>=|={1,3}|<=|!={1,2}|)( *)([^=!<>]*)$/gim
    const nameRuleParts = nameRuleExp.exec(str) ?? []
    try {
      const name = nameRuleParts[1].trim()
      let operator = nameRuleParts[3].trim()
      if (operator === '===' || operator === '==') {
        operator = '='
      } else if (operator === '!==') {
        operator = '!='
      }
      const valueStr = nameRuleParts[5].trim()
      const value: RuleValue = NameRule.evaluateValueString(valueStr)
      return new NameRule(valueResolver, name, value, operator as ComparisonOperator)
    } catch (e) {
      throw new Error(`Invalid expression: ${str}`)
    }
  }

  public static evaluateValueString(valueStr: string) {
    if (isBoolean(valueStr)) {
      return evaluateBoolean(valueStr)
    } else if (isNumber(valueStr)) {
      return evaluateNumber(valueStr)
    } else {
      return evaluateString(valueStr)
    }
  }

  constructor(
    private valueResolver: ValueResolver,
    private name: string,
    private value: RuleValue = true,
    private operator: ComparisonOperator = '=',
  ) {}

  public isValid(): boolean {
    let actualValue = this.valueResolver(this.name)
    let operator
    let value
    if (!this.operator) {
      operator = '='
      value = true
      actualValue = !!actualValue
    } else {
      operator = this.operator
      value = this.value
    }
    if (operator !== '=' && operator !== '!=' && typeof actualValue !== 'number') {
      throw new Error(`Cannot use operator ${operator} with value of type ${typeof actualValue} (${actualValue})`)
    }
    switch (operator) {
      case '=':
        return actualValue === value
      case '!=':
        return actualValue !== value
      case '>':
        return actualValue > value
      case '<':
        return actualValue < value
      case '>=':
        return actualValue >= value
      case '<=':
        return actualValue <= value
    }
    return false
  }

  getNames(): readonly string[] {
    return [this.name]
  }
}
