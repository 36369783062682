import type { ExtensionConfig } from '@goto/shell-common'

const baseUrl = '/experiences/cc-config'

const extensionConfig: ExtensionConfig = {
  id: '@ucc/cc-config/extension',
  url: new URL(`${baseUrl}/extension.js`, location.origin),
  prerequisites: {
    ccFlags: 'cc-config.gtc || engagements',
  },
  optionalDependencies: [
    '@dce/engagements-web-components-loader-extension'
  ],
  integrationConfig: {
    shouldLoadInIntegration: true,
  },
}

export default extensionConfig
