import type { Token } from '@getgo/auth-client'
import Auth from '@getgo/auth-client'
import { BadgeStatus, Plugins } from '@getgo/container-client'
import { getToken, setRefreshToken, setToken } from '.'
import { getShellLogger } from '../../common/logger'
import {
  navigateToExternal,
  getLocationSearch,
  popFromLocalStorage,
  getFromLocalStorage,
  setToLocalStorage,
  getLocationPathname,
  getLocationOrigin,
  getLocationHREF,
} from '../../common/dom-helpers'
import type { EnvironmentConfig } from '../../environments'
import { environment, LocalStorageKeys, BASEURL_AUTH } from '../../environments'
import { UnauthenticatedError } from '../errors'
import { authCodeVerificationKey } from './constants'
import { IsLoggedInState } from './isLoggedIn-state'
import { refreshAuthentication, refreshTokenInterval } from './refresh'
import { removeToken, removeRefreshToken, haveRefreshToken, haveToken } from './token'
import {
  storeInflightRequest,
  initRefreshTokenCounter,
  safeJsonParse,
  createRandomString,
  sha256,
  bufferToBase64UrlEncoded,
  currentRouteIsGuarded,
} from './utils'
import { navigateToUrl } from '../../common/helpers'
import { sanitizeExternalIntegrationRoute } from '../../core/integrations-helpers'
import { LANDING_ROUTE, ROOT_ROUTE } from '../../common/routes'
import { isBadgePluginAvailable } from '../../core/helpers/container-client'
import { getExternalInterface } from '../external-interface'
import { emitOnLogoutEvent } from '../shell-namespace'

export abstract class AuthBase {
  abstract authProcess(env: EnvironmentConfig, loginOnce: boolean, inflight?: string): void
  abstract getLogoutURL(env?: EnvironmentConfig): string
  abstract requestToken(): Promise<Token>
  private unguardedRoutes: readonly string[] = []

  /**
   * @description Set the unguarded routes for Authentication
   * @param unguardedRoutes The routes that should not be protected by auth.
   */
  setUnguardedRoutes(unguardedRoutes: readonly string[]) {
    this.unguardedRoutes = unguardedRoutes
  }

  protected navigateToLandingPage() {
    const origin = getLocationOrigin()
    const pathname = getLocationPathname()
    this.setInflightRequest(origin + pathname)
    navigateToUrl(LANDING_ROUTE)
  }

  /**
   * @description Authentication flow: redirect on the login page if needed or get the token.
   * @param unguardedRoutes The routes that should not be protected by auth.
   * @returns a token.
   */
  async auth() {
    try {
      this.init()
      if (this.isDisconnectedIntegrationOnGuardedRoute()) {
        this.navigateToLandingPage()
      }
      if (this.isRedirectedAuthPage()) {
        return await this.completeAuthProcess()
      } else if (!haveToken() && haveRefreshToken()) {
        await refreshAuthentication()
      } else if (this.isAuthenticationRequired() && !haveToken()) {
        const originURL = new URL(getLocationOrigin())
        const pathname = sanitizeExternalIntegrationRoute(getLocationPathname())
        const destinationURL = new URL(pathname, originURL).href
        this.setInflightRequest(destinationURL)

        if (pathname !== ROOT_ROUTE) {
          await refreshAuthentication()
        }
        // verify if refreshAuthentication created a valid token
        if (haveToken()) {
          navigateToUrl(destinationURL)
        } else {
          this.navigateToLandingPage()
        }
      }
      if (haveToken()) {
        IsLoggedInState.getInstance().update(true)
      }
      return getToken() // completeAuthProcess(), refreshAuthentication() might (re-)set the token
    } catch (err) {
      getShellLogger().error('shell authentication flow encountered an error', err)
      this.authErrorHandler(err, () => {
        throw err
      })
    }
  }

  /**
   * @description Call the init method of the @getgo/auth-client library with the existing token(optional).
   * @returns a token.
   */
  init() {
    const authClientInstance = this.getAuthClientInstance()
    authClientInstance.init(getToken())
  }

  /**
   * @description Login flow combining web and container side.
   * Container side call the plugin 'Browser Auth' from container-client library.
   */
  async login(inflight?: string) {
    this.authProcess(environment(), false, inflight)
  }
  async loginOnce() {
    this.authProcess(environment(), true)
  }

  /**
   * @description Logout flow combining web and container side.
   */
  async logout() {
    emitOnLogoutEvent()
    const url = this.getLogoutURL(environment())
    this.removeTokens()
    if (isBadgePluginAvailable()) {
      Plugins.Badge.setBadgeCount(0)
      if ('setBadgeStatus' in Plugins.Badge) {
        Plugins.Badge.setBadgeStatus(BadgeStatus.Offline)
      }
    }
    navigateToExternal(url)
  }

  /**
   * @description Set all the configuration needed.
   * @param state is different between web and container.
   * @returns the configuration needed for the SSO.
   */
  async getLoginConfig<T>(state: T, redirectUri: string, url: string) {
    const { authClientId } = environment()
    return {
      response_type: 'code',
      client_id: authClientId,
      state: state,
      redirect_uri: redirectUri,
      code_challenge: await this.getCodeChallenge(),
      code_challenge_method: 'S256',
      url: url,
    }
  }

  /**
   * @returns an instance of the auth library @getgo/auth-client with the proper configuration.
   */
  getAuthClientInstance() {
    const { authUrl, authRedirectUrl, authClientId } = environment()

    return new Auth({
      redirect_url: authRedirectUrl,
      client_id: authClientId,
      url: authUrl,
    })
  }

  /**
   * @description Validate the authenticity of the call(nonce validation) & request/store the received token.
   * @returns a token.
   */
  async completeAuthProcess(params?: URLSearchParams) {
    const { nonce, inflightRequest } = this.getStateFromSearch(params)
    this.validateNonce(nonce)
    const token = await this.requestToken()
    this.initializeToken(token)
    this.setInflightRequest(inflightRequest)
    return token
  }

  /**
   * @description Store and modify the history with the inflight request.
   * @param inflightRequest
   */
  setInflightRequest(inflightRequest = '') {
    storeInflightRequest(inflightRequest)
    history.replaceState(null, '', inflightRequest)
  }

  /**
   * Bind the system with the given token.
   * @param token received by the Auth process.
   */
  initializeToken(token: Token) {
    initRefreshTokenCounter()
    setRefreshToken(token.refresh_token)
    setToken(token)
    refreshTokenInterval(token)
  }

  /**
   * @description Compare if the stored nonce in the local storage is equal to the server's nonce.
   * @param stateNonce Nonce received from the server.
   */
  validateNonce(stateNonce: string) {
    const nonce = popFromLocalStorage(LocalStorageKeys.gotoNonce)
    if (!nonce || !(stateNonce === nonce)) {
      throw new UnauthenticatedError('Nonce is invalid.')
    }
  }

  /**
   * @returns the state parameter object from URL.
   */
  getStateFromSearch(params = new URLSearchParams(getLocationSearch())) {
    return safeJsonParse<{ nonce: string; inflightRequest: string }>(decodeURIComponent(params.get('state') ?? ''), {
      inflightRequest: window.location.origin,
    })
  }

  /**
   * @returns state object.
   */
  getState(inflight?: string) {
    return {
      inflightRequest: inflight ? inflight : getLocationHREF(),
      nonce: getFromLocalStorage(LocalStorageKeys.gotoNonce) ?? '',
    }
  }

  /**
   * @description Set in the local storage an encoded nonce and return it.
   */
  setLocalStorageEncodedNonce() {
    const encodedNonce = btoa(createRandomString())
    setToLocalStorage(LocalStorageKeys.gotoNonce, encodedNonce)
    return encodedNonce
  }

  /**
   * @description Generate the code challenge for the PKCE flow.
   * @returns the code challenge.
   */
  async getCodeChallenge() {
    const codeChallengeBuffer = await sha256(this.setSessionStorageCodeVerifier())
    return bufferToBase64UrlEncoded(codeChallengeBuffer)
  }

  /**
   * @description Set in the session storage the code verifier and return it.
   * @returns code verifier.
   */
  setSessionStorageCodeVerifier() {
    const codeVerifier = createRandomString()
    sessionStorage.setItem(authCodeVerificationKey, codeVerifier)
    return codeVerifier
  }

  /**
   * @description Remove token/refreshToken from the local storage.
   */
  removeTokens() {
    removeToken()
    removeRefreshToken()
  }

  /**
   * @description Return if the page is the base auth redirect page.
   * @returns a boolean.
   */
  isRedirectedAuthPage() {
    return getLocationPathname().startsWith(BASEURL_AUTH.redirect)
  }

  /**
   * @description Return if the authentication is required with the actual route.
   * @param unguardedRoutes list of unguarded routes.
   * @returns a boolean.
   */
  isAuthenticationRequired() {
    return currentRouteIsGuarded(this.unguardedRoutes)
  }

  /**
   * @description Checks whether we are running in an integration that is disconnected from the companion and we are trying to access a guarded route.
   * @returns a boolean.
   */
  isDisconnectedIntegrationOnGuardedRoute() {
    return (
      getExternalInterface().supportsCompanion && !getExternalInterface().available && this.isAuthenticationRequired()
    )
  }

  /**
   * @description Handler for authentication errors
   * @param error error received
   * @param unhandledErrorCallback callback to the called if the error is not handled
   */
  authErrorHandler(error: unknown, unhandledErrorCallback?: () => void) {
    // If the authentication failed because of nonce error, then redirect to error page
    if (error instanceof UnauthenticatedError) {
      navigateToUrl(error.url)
    } else {
      unhandledErrorCallback?.()
    }
  }
}
