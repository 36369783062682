import { getIntegrationMetaData, MS_TEAMS_INTEGRATION_ID } from './integrations-helpers'
import { chameleonThemeStylesheetBaseUrl } from "../environments";

enum FileName {
  msteams = 'msteams',
  gotoapp = 'gotoapp',
}

export const CHAMELEON_THEME_STYLE = 'chameleon-theme-style'

export const DEFAULT_CHAMELEON_THEME_STYLE_VERSION = 'latest'

const getChameleonThemesUrl = (version: string, fileName: string) =>
  `${chameleonThemeStylesheetBaseUrl}/${version}/${fileName}.css`

const cssFileNames: { [key: string]: string } = {
  [MS_TEAMS_INTEGRATION_ID]: FileName.msteams,
  default: FileName.gotoapp,
}

const getCSSFileName = () => {
  const theme = getIntegrationMetaData()?.id || 'default'
  return cssFileNames[theme]
}

export const appendInHeaderChameleonStylesLinkElement = (version?: string) => {
  const validVersion = !version ? DEFAULT_CHAMELEON_THEME_STYLE_VERSION : version
  const element = document.head.querySelector(`#${CHAMELEON_THEME_STYLE}`)
  if (!element) {
    const chameleonThemesUrl = getChameleonThemesUrl(validVersion, getCSSFileName())
    const linkElement = document.createElement('link')
    linkElement.id = CHAMELEON_THEME_STYLE
    linkElement.rel = 'stylesheet'
    linkElement.href = chameleonThemesUrl
    document.head.appendChild(linkElement)
  }
}
