import { SHELL_APP_PREFIX } from '../constants'
import type { GoToExperienceConfigWithRootRoutes } from '../models'
import {
  CALENDAR_SETTINGS_ROUTE,
  APPEARANCE_SETTINGS_ROUTE,
  SHELL_SETTINGS_APP_LAUNCH_ROUTE,
  NOTIFICATIONS_SETTINGS_ROUTE,
  LANGUAGE_SETTINGS_ROUTE,
  KEYBOARD_SHORTCUT_SETTINGS_ROUTE,
} from '../../common/routes'

import GoToAppLaunchSettings from '../../packages/app-launch-settings/goto-app-launch-settings'
import GoToCalendarSettings from '../../packages/calendar/goto-calendar-settings'
import GoToAppearanceSettings from '../../packages/appearance/goto-appearance-settings'
import GoToKeyboardShortcutSettings from '../../packages/keyboard-shortcut/goto-keyboard-shortcut-settings'
import GoToNotificationsSettings from '../../packages/notifications-settings/goto-notifications-settings'
import GoToLanguageSettingsPage from '../../packages/language-settings/goto-language-settings'
import type { Application } from 'single-spa'
import { type ComponentDefinition } from '@goto/shell-common'

const asSingleSpaApp = (cd: ComponentDefinition) => cd as unknown as Application

export const gotoAppLaunchSettingsExperienceConfig: GoToExperienceConfigWithRootRoutes = {
  app: asSingleSpaApp(GoToAppLaunchSettings),
  name: `${SHELL_APP_PREFIX}/app-launch`,
  rootRoutes: [SHELL_SETTINGS_APP_LAUNCH_ROUTE],
}

export const gotoCalendarExperienceConfig: GoToExperienceConfigWithRootRoutes = {
  app: asSingleSpaApp(GoToCalendarSettings),
  name: `${SHELL_APP_PREFIX}/calendar`,
  rootRoutes: [CALENDAR_SETTINGS_ROUTE],
}

export const goToAppearanceExperienceConfig: GoToExperienceConfigWithRootRoutes = {
  app: asSingleSpaApp(GoToAppearanceSettings),
  name: `${SHELL_APP_PREFIX}/appearance`,
  rootRoutes: [APPEARANCE_SETTINGS_ROUTE],
}

export const gotoKeyboardShortcutExperienceConfig: GoToExperienceConfigWithRootRoutes = {
  app: asSingleSpaApp(GoToKeyboardShortcutSettings),
  name: `${SHELL_APP_PREFIX}/keyboard-shortcut`,
  rootRoutes: [KEYBOARD_SHORTCUT_SETTINGS_ROUTE],
}

export const gotoNotificationsSettingsExperienceConfig: GoToExperienceConfigWithRootRoutes = {
  app: asSingleSpaApp(GoToNotificationsSettings),
  name: `${SHELL_APP_PREFIX}/notifications`,
  rootRoutes: [NOTIFICATIONS_SETTINGS_ROUTE],
}

export const gotoLanguageSettingsExperienceConfig: GoToExperienceConfigWithRootRoutes = {
  app: asSingleSpaApp(GoToLanguageSettingsPage),
  name: `${SHELL_APP_PREFIX}/language`,
  rootRoutes: [LANGUAGE_SETTINGS_ROUTE],
}
