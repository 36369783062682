import {
  getFromLocalStorage,
  getLocationPathname,
  getLocationSearchParamsByName,
  setToLocalStorage,
} from '../common/dom-helpers'
import { isMobileUser } from '../common/user-agent-helpers'
import { type CommandsNamespaceDefinition, type EventsNamespaceDefinition } from '@goto/shell-common'
import { getExtensionsManager } from '../extensions/extensions-manager'
import { type ExtensionManagerPrivate } from '../extensions/extensions-manager-intf'
import { AccountNamespace } from '../services/account'
import { I18NNamespace } from '../services/i18n'
import { ShellNamespace } from '../services/shell-namespace'

export const SHELL_EVENTS_TO_FORWARD_TO_COMPANION: EventsNamespaceDefinition[] = [
  {
    namespace: I18NNamespace,
  },
  {
    namespace: AccountNamespace,
  },
  {
    namespace: ShellNamespace,
    // Do not pass the extensionReady and applicationReady events to the companion app
    eventNames: ['reloadPage', 'appChanged', 'routeChanged', 'themeChanged'],
  },
]
export const MS_TEAMS_INTEGRATION_ID = 'teamsPlugin'
export const START_MS_TEAMS_PLUGIN_URL = 'gotoforteams://open'
export const START_MS_TEAMS_PLUGIN_INTERNAL_ROUTE = '/start-goto-for-teams'
export const DOWNLOAD_MS_TEAMS_PLUGIN_URL = 'https://support.goto.com/connect/help/set-up-goto-for-microsoft-teams'

const DEFAULT_INTEGRATION_VERSION = 'alpha'
const MS_TEAMS_ROUTE = '/ms-teams'
export const EXTERNAL_INTEGRATION_ROUTES = [MS_TEAMS_ROUTE]
const ID_ROUTES: { readonly [key: string]: string } = {
  [MS_TEAMS_ROUTE]: MS_TEAMS_INTEGRATION_ID,
}
const INTEGRATION_METADATA_LOCAL_STORAGE_KEY = 'shell-integration-metadata'

const VERSION_QUERYSTRING_PARAM_NAME = 'v'

const NO_DATA = { id: '', version: '' }

let integrationMetadata: IntegrationMetaData | undefined

export interface IntegrationMetaData {
  id: string
  version: string
}

export const sanitizeExternalIntegrationRoute = (pathname: string) => {
  EXTERNAL_INTEGRATION_ROUTES.forEach(route => {
    if (pathname.startsWith(route)) {
      pathname = '/'
    }
  })

  return pathname
}
export const getIntegrationRoute = () =>
  EXTERNAL_INTEGRATION_ROUTES.find(pageRoute => pageRoute === getLocationPathname())
export const isOnIntegrationRoute = () => !!getIntegrationRoute()
export const getIntegrationIdForRoute = (route: string) => ID_ROUTES[route]

export const getIntegrationMetaData = () => {
  if (!integrationMetadata) {
    const storageValue = getFromLocalStorage(INTEGRATION_METADATA_LOCAL_STORAGE_KEY)
    const idInPath = getIntegrationIdForRoute(getLocationPathname())
    if (storageValue && !idInPath) {
      try {
        integrationMetadata = JSON.parse(storageValue)
      } catch (e) {
        // do nothing
      }
    }
    if (!integrationMetadata) {
      if (idInPath) {
        integrationMetadata = {
          id: idInPath,
          version: getLocationSearchParamsByName(VERSION_QUERYSTRING_PARAM_NAME) ?? DEFAULT_INTEGRATION_VERSION,
        }
      } else {
        integrationMetadata = NO_DATA
      }
    }
  }
  return integrationMetadata !== NO_DATA ? integrationMetadata : undefined
}

export const updateIntegrationMetaData = () => {
  const metadata = getIntegrationMetaData()
  if (metadata) {
    try {
      setToLocalStorage(INTEGRATION_METADATA_LOCAL_STORAGE_KEY, JSON.stringify(metadata))
    } catch (e) {
      // do nothing
    }
  }
}

export const clearIntegrationMetaDataCache = () => {
  integrationMetadata = undefined
}

let commandsToForwardToCompanion: CommandsNamespaceDefinition[] | undefined = undefined

const getExtensionsManagerPrivate = () => getExtensionsManager() as unknown as ExtensionManagerPrivate

/**
 * Retrieves all the commands that should be ran on the Companion app if available
 * @returns commands that should be ran on the Companion app if available
 */
export const getCommandsToForwardToCompanion = (): CommandsNamespaceDefinition[] => {
  if (commandsToForwardToCompanion === undefined) {
    commandsToForwardToCompanion = getExtensionsManagerPrivate()
      .getLoadedExtensionConfigs()
      .reduce((acc, config) => {
        if (config.integrationConfig?.commandsToForwardToCompanion) {
          acc.push(...config.integrationConfig.commandsToForwardToCompanion)
        }
        return acc
      }, [] as CommandsNamespaceDefinition[])
  }
  return commandsToForwardToCompanion
}

let eventsToForwardToCompanion: EventsNamespaceDefinition[] | undefined = undefined

/**
 * Retrieves all the events that should be forwarded to the Companion app if available
 * @returns events that should be forwareded to the Companion app if available
 */
export const getEventsToForwardToCompanion = (): EventsNamespaceDefinition[] => {
  if (eventsToForwardToCompanion === undefined) {
    eventsToForwardToCompanion = getExtensionsManagerPrivate()
      .getLoadedExtensionConfigs()
      .reduce((acc, config) => {
        if (config.integrationConfig?.eventsToForwardToCompanion) {
          acc.push(...config.integrationConfig.eventsToForwardToCompanion)
        }
        return acc
      }, [] as EventsNamespaceDefinition[])
  }
  return eventsToForwardToCompanion.concat(SHELL_EVENTS_TO_FORWARD_TO_COMPANION)
}

/**
 * Checks if the current integration is Microsoft Teams.
 * @returns Returns true if the current integration is Microsoft Teams, otherwise false.
 */
export const isIntegration = (): boolean => [MS_TEAMS_INTEGRATION_ID].includes(getIntegrationMetaData()?.id ?? '') //getIntegrationMetaData()?.id === MS_TEAMS_INTEGRATION_ID

/**
 * Checks if the user is accessing Microsoft Teams on a mobile device.
 * @returns Returns true if the user is accessing Microsoft Teams on a mobile device, otherwise returns false.
 */
export const isIntegrationMobile = () => isIntegration() && isMobileUser()
