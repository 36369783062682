import { LitElement, html, state, query } from 'lit-element'
import appLaunchSettingsStyles from './app-launch-settings.styles.scss'
import { t } from '../../directives/translate'
import { containerAPI } from '../../services/container'
import { Container } from '@getgo/container-client'
import { isContainer } from '../../services/container/helpers'
import { type TemplateResult } from 'lit-html'
import { type SelectComponent } from '@getgo/chameleon-web'

export enum AppLaunchSelectValues {
  off = 'off',
  foreground = 'foreground',
  background = 'background',
}

export const AppLaunchSelectOptions = [
  {
    value: AppLaunchSelectValues.off,
    label: t('Launch manually'),
  },
  {
    value: AppLaunchSelectValues.foreground,
    label: t('Launch in foreground'),
  },
  {
    value: AppLaunchSelectValues.background,
    label: t('Launch in background'),
    canShow: () => !!containerAPI.containerSettings?.getMinimizedAtLogin,
  },
]

export class GoToAppLaunchSettings extends LitElement {
  static readonly tagName = 'goto-app-launch-settings'

  @state() private isOpenContainerAtStartupDisabled = true
  @state() private appLaunchSelectValue: AppLaunchSelectValues | undefined = undefined
  @query('#App-launch-select') private readonly appLaunchSelect: SelectComponent | undefined

  static get styles() {
    return appLaunchSettingsStyles
  }

  async connectedCallback() {
    super.connectedCallback()
    await this.initValues()
  }

  disconnectedCallback() {
    super.disconnectedCallback()
  }

  async initValues() {
    this.isOpenContainerAtStartupDisabled = !isContainer() || !Container.isPluginAvailable('ContainerSettings')
    const openAtLogin = await containerAPI.containerSettings?.getOpenAtLogin?.() ?? false
    const minimizedAtLogin = await containerAPI.containerSettings?.getMinimizedAtLogin?.() ?? false
    this.setAppLaunchSelectedValue(openAtLogin, minimizedAtLogin)
  }

  private setAppLaunchSetting(value: AppLaunchSelectValues) {
    containerAPI.containerSettings.setOpenAtLogin?.(
      value == AppLaunchSelectValues.foreground || value == AppLaunchSelectValues.background,
    )
    containerAPI.containerSettings.setMinimizedAtLogin?.(value == AppLaunchSelectValues.background)
  }

  private setAppLaunchSelectedValue(openAtLogin: boolean, minimizedAtLogin: boolean) {
    if (openAtLogin && minimizedAtLogin) {
      this.appLaunchSelectValue = AppLaunchSelectValues.background
    } else if (openAtLogin && !minimizedAtLogin) {
      this.appLaunchSelectValue = AppLaunchSelectValues.foreground
    } else {
      this.appLaunchSelectValue = AppLaunchSelectValues.off
    }
  }

  private getAppLaunchSelectOptions() {
    const optionsToShow: Array<TemplateResult> = []
    AppLaunchSelectOptions.forEach(option => {
      if (!option.canShow  || option.canShow()) {
        optionsToShow.push(html`<chameleon-option value=${option.value}>${option.label}</chameleon-option>`)
      }
    })
    return optionsToShow
  }

  private async handleAppLaunchChangeEvent() {
    const value = this.appLaunchSelect?.value as AppLaunchSelectValues ?? AppLaunchSelectValues.off
    this.setAppLaunchSetting(value)
  }

  private getAppLaunchSelect() {
    return this.appLaunchSelectValue
      ? html` <chameleon-select
          id="App-launch-select"
          selected-value=${this.appLaunchSelectValue}
          ?disabled=${this.isOpenContainerAtStartupDisabled}
          @change=${this.handleAppLaunchChangeEvent}
        >
          ${this.getAppLaunchSelectOptions()}
        </chameleon-select>`
      : html`<div class="select-skeleton"><chameleon-skeleton variant="rectangle"></chameleon-skeleton></div>`
  }

  render() {
    return html`
      <div class="settings-wrapper">
        <div class="settings-header">
          <chameleon-typography tag="h1" variant="heading-large">${t('App launch')} </chameleon-typography>
        </div>
        <div class="settings-container">
          <div class="top-settings">
            <chameleon-typography tag="p" variant="body-medium"
              >${t('Configure app launch settings')}
            </chameleon-typography>
          </div>
          <div class="settings-section-container">
            <div class="default-content">
              <chameleon-typography class="content-headers" tag="p" variant="body-large"
                >${t('Launch the GoTo app when computer starts')}
              </chameleon-typography>
              <chameleon-typography class="content-description" tag="p" variant="caption-default-01" color="text-02">
                ${t(
                  'Choose how to launch the GoTo App when your computer starts.',
                )}
              </chameleon-typography>
            </div>
            <div class="settings-section-child-right">${this.getAppLaunchSelect()}</div>
          </div>
        </div>
      </div>
    `
  }
}

declare global {
  interface HTMLElementTagNameMap {
    readonly [GoToAppLaunchSettings.tagName]: GoToAppLaunchSettings
  }
}
