import type { CSSResult } from 'lit-element'
import { LitElement, html } from 'lit-element'
import { unsafeSVG } from 'lit-html/directives/unsafe-svg'

import { t } from '../../directives/translate'
import style from './calendar-connected.styles.scss'
import { SVG_CHECKMARK_OUTLINED } from '@getgo/chameleon-icons'
import { getWindow } from '../../common/dom-helpers'

export class GoToCalendarConnected extends LitElement {
  static readonly tagName = 'goto-calendar-connected'

  static get styles(): CSSResult {
    return style
  }

  render() {
    return html`
      <chameleon-empty-state size="medium">
        <chameleon-svg class="check-icon" slot="icon">${unsafeSVG(SVG_CHECKMARK_OUTLINED)}</chameleon-svg>
        <chameleon-typography variant="heading-medium" tag="h1" slot="title">
          ${t('Calendar successfully connected')}
        </chameleon-typography>
        ${t("You're almost done. After closing this window, restart GoTo to see all your meetings and events appear.")}
        <div slot="actions">
          <chameleon-button size="small" @click=${() => getWindow().close()}> ${t('Close')} </chameleon-button>
        </div>
      </chameleon-empty-state>
    `
  }
}

declare global {
  interface HTMLElementTagNameMap {
    readonly [GoToCalendarConnected.tagName]: GoToCalendarConnected
  }
}
