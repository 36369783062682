import type { authenticatedFetch } from '../auth'
import type { CISDateRange } from './date-utils'

export interface CalendarConnectorAPI {
  /**
   * Prepare a single user connection to a calendar provider.
   *
   * Resolves to the authorization url if successful or rejects to an HttpResponseError if unsuccessful.
   *
   * https://artifactory.prodwest.citrixsaassbe.net/artifactory/documentation/g2m/calendar-integration-service/api/index.html#resources-connect-prepare-user-connection_ok
   *
   * @param externalUserKey unique idenitifer of the calendar's owner
   * @param providerName ProviderName - the name of the calendar service provider
   * @throws HttpResponseError 409 - calendar is already connected
   */
  connect(externalUserKey: string, providerName: ProviderName, redirectUrl: string): Promise<AuthorizatioUrl>

  /**
   * Disconnect a calendar.
   *
   * Resolves if disconnect is successful or rejects to an HttpResponseError if unsuccessful.
   *
   * https://artifactory.prodwest.citrixsaassbe.net/artifactory/documentation/g2m/calendar-integration-service/api/index.html#resources-connect-delete-user-connection_ok
   *
   * @param externalUserKey unique idenitifer of the calendar's owner
   * @throws HttpResponseError 404 - no calendar is connected
   */
  disconnect(externalUserKey: string): Promise<void>

  /**
   * Get details about the connection.
   *
   * https://artifactory.prodwest.citrixsaassbe.net/artifactory/documentation/g2m/calendar-integration-service/api/index.html#resources-connect-get-user-connection_ok
   *
   *
   * @param externalUserKey unique idenitifer of the calendar's owner
   * @throws HttpResponseError 404 - no connection for account
   */
  getConnection(externalUserKey: string): Promise<CalendarConnectionResponse>

  /**
   * Returns a boolean if a calendar connection for the given external user key exists
   * @param externalUserKey unique idenitifer of the calendar's owner
   */
  isConnected(externalUserKey: string): Promise<boolean>

  /**
   * Returns the connected service type or null if there's no connection
   * @param externalUserKey unique idenitifer of the calendar's owner
   */
  getConnectedServiceType(externalUserKey: string): Promise<ConnectedServiceType | null>

  /**
   * Takes a nextPageToken and fetches the next page of calendar events
   *
   * https://artifactory.prodwest.citrixsaassbe.net/artifactory/documentation/g2m/calendar-integration-service/api/index.html#resources-events-next-events_ok
   *
   * @param nextPageToken
   * @throws HttpResponseError
   */
  getNextPage(nextPageToken: string): Promise<CalendarEventsResponse>

  // I'm not sure how to define a static method in an interface.
  // formatGetEventsAPIParams(from: string, to: string, options: GetEventsOptions): GetCalendarEventsAPIParams
}

export interface CalendarCacheAPI {
  /**
   * Clear the cache.
   */
  clear(): void

  /**
   * Takes a unix timestamp range and returns a new range that spans days the first and last days with missing values.
   *
   * @param CISDateRange
   */
  getMissingDatesRange(dateRange: CISDateRange): CISDateRange

  /**
   * Check if each day in range has already been cached
   * @param CISDateRange
   */
  isDateRangeCached(dateRange: CISDateRange): boolean

  /**
   * Converts the calendarEventResponse object to the cache-specific format, and caches it.
   * @param from Unix time 'from' value
   * @param to Unix time 'to' value
   * @param CalendarEvent the CalendarEvents to merge into the cache
   */
  setEvents(dateRange: CISDateRange, events: readonly CalendarEvent[]): void

  /**
   * We parse the events list to retrieve the events from the requested date range.
   *
   * We need some bit of logic to emulate what the backend would return for that same request
   * (in the cases when the days or some of the days were already cached).
   *
   * We return a event if that event spans at least part of the days requested (ending at 0000 counts).
   *
   * @param dateRange Date range the user requested
   * @param excludePrivate Do we exclude private events?
   * @returns the events list spawning the date range requested
   */
  getEvents(dateRange: CISDateRange, excludePrivate?: boolean): readonly CalendarEvent[]
}

// eslint-disable-next-line functional/prefer-readonly-type
export type EventsCacheMap = Map<string, CalendarEvent>

export type CalendarConnectorOpts = { readonly apiBaseUrl: string }

export interface CalendarEventsResponse {
  readonly events: readonly CalendarEvent[]
  readonly nextPageToken?: string
}

export interface CalendarEvent {
  readonly eventId: string
  readonly title: string
  readonly description: string
  readonly location?: string
  readonly start: string // Ie 2021-08-31T08:00:00-07:00
  readonly end: string
  readonly recurring: boolean
  readonly organizerEmail: string
  readonly organizerName?: string
  readonly privateEvent: boolean
  readonly attendees: readonly Attendee[]
  readonly meetingUrl?: string
  readonly conference?: ConferenceEvent
  readonly allDayEvent: boolean
  readonly signature: string
  readonly serviceType?: ConnectedServiceType
}

export interface Attendee {
  readonly id: string
  readonly name: string | null
}

export interface ConferenceEvent {
  readonly vendor: string
  readonly id?: string
  readonly joinLink: string
  readonly startLink?: string
  readonly profileId?: string
  readonly profileLink?: string
  readonly displayName?: string
  readonly canStart: boolean
  readonly sipUri?: string
  readonly renderedSipUri?: string
  readonly roomName?: string
  readonly webinar?: Webinar | null
  readonly training?: Training | null
}

export interface Webinar {
  readonly recurrenceKey: string
  readonly webinarKey: string | null
  readonly webinarId: string | null
  readonly formattedWebinarId: string | null
  readonly registrationUrl: string | null
  readonly subject: string
  readonly description: string
  readonly organizerName: string
  readonly organizerEmail: string
  readonly userVerificationMethod: string | null
  readonly broadcast: boolean
}

export interface Training {
  readonly registrationUrl: string | null
  readonly registrationUrlValidated: boolean | null
  readonly confirmationUrl?: string | null
  readonly status: string | null
  readonly subject: string
  readonly description: string
  readonly scheduledStartTime: string
  readonly scheduledEndTime: string
  readonly organizerFirstName: string
  readonly organizerLastName: string
  readonly organizerEmail: string
}

// https://artifactory.prodwest.citrixsaassbe.net/artifactory/documentation/g2m/calendar-integration-service/api/index.html#_query_parameters
export interface GetCalendarEventsAPIParams {
  /**
   * Start date (min)
   *
   * format is "yyyy-MM-dd"
   * e.g. "2021-01-01"
   */
  readonly from: string
  /**
   * End date (max)
   *
   * format is "yyyy-MM-dd"
   * e.g. "2021-02-01"
   */
  readonly to: string
  /**
   * True if the list should not include private events.
   */
  readonly excludePrivate: boolean
  /**
   *  url to be called when an event is updated. Cannot be used when pusherChannel is used. Must be base64url (RFC 4648 §5) encoded.
   */
  readonly notificationUrl?: string
  /**
   *  Notification channel id to be called when an event is updated. Cannot be used when pusherChannel is used. Must be base64url (RFC 4648 §5) encoded.
   */
  readonly notificationChannelId?: string
  /**
   *  User's timezone id as set in their profile. Ex.: 'America/Montreal'
   */
  readonly tzid?: string
}

/**
 * the third argument to the getEvents method
 */
export type GetEventsOptions = Omit<GetCalendarEventsAPIParams, 'from' | 'to' | 'tzid'>
export type GetEventsAPIOptions = Omit<GetCalendarEventsAPIParams, 'from' | 'to'>

export type FetchClient = typeof authenticatedFetch

/**
 * The object returned when a calendar is connected
 *
 * https://artifactory.prodwest.citrixsaassbe.net/artifactory/documentation/g2m/calendar-integration-service/api/index.html#resources-connect-prepare-user-connection_ok
 */
export interface CalendarConnectResponse {
  readonly authorizationUrl: string
}

export interface CalendarConnection {
  readonly id: string
  readonly providerType: ProviderType
  readonly providerSubType: ProviderName
  readonly createdAt: ISO8601Timestamp
  readonly connectionType: ConnectionType
}

export interface CalendarConnectionResponse extends Partial<Readonly<CalendarConnection>> {
  readonly connected: boolean
  readonly connectedService?: ConnectedServiceType
  readonly providerIdentity?: string
}

export enum ProviderType {
  GOOGLE = 'GOOGLE',
  OFFICE365 = 'MS_GRAPH',
  EXCHANGE_ONLINE = 'EXCHANGE_ONLINE',
  EXCHANGE = 'EXCHANGE',
  CRONOFY = 'CRONOFY',
}

export enum ProviderName {
  GOOGLE_CRONOFY = 'google',
  OFFICE365_CRONOFY = 'office365',
  EXCHANGE_CRONOFY = 'exchange',
  GOOGLE_DIRECT = 'google_direct',
  OFFICE365_DIRECT = 'office365_direct',
  EXCHANGE_ONLINE = 'exchange_online',
  EXCHANGE_DIRECT = 'exchange_direct',
  CHOOSE = 'CHOOSE',
}

type ISO8601Timestamp = string

export enum ConnectionType {
  SINGLE_USER = 'SINGLE_USER',
  DELEGATED = 'DELEGATED',
  ENTERPRISE = 'ENTERPRISE',
}

export enum ConnectedServiceType {
  OFFICE365 = 'office365',
  GOOGLE = 'google',
}

export const providerNameToServiceName: Record<string, ConnectedServiceType> = {
  [ProviderName.OFFICE365_CRONOFY]: ConnectedServiceType.OFFICE365,
  [ProviderName.OFFICE365_DIRECT]: ConnectedServiceType.OFFICE365,
  // Exchange Chronofy is somehow Office365
  [ProviderName.EXCHANGE_CRONOFY]: ConnectedServiceType.OFFICE365,
  [ProviderName.GOOGLE_CRONOFY]: ConnectedServiceType.GOOGLE,
  [ProviderName.GOOGLE_DIRECT]: ConnectedServiceType.GOOGLE,
}

export type AuthorizatioUrl = string
