import { getFromLocalStorage } from '../../common/dom-helpers'
import { LocalStorageKeys } from '../../environments'
import type { NotificationSettings } from './models'

export const notificationSettingsInitialState = {
  general: {
    message: true,
    call: true,
    meeting: true,
  },
  doNotDisturb: {
    message: true,
    call: true,
    meeting: true,
  },
}

export type GetNotificationSettingsType = typeof getNotificationSettings

const getNotificationSettingsFromLocalStorage = (localStorageKey: string): NotificationSettings | null => {
  try {
    const localStorageValue = getFromLocalStorage(localStorageKey)

    if (localStorageValue === null) {
      return null
    }

    if (localStorageKey === LocalStorageKeys.jivewebNotificationSettings) {
      const localNotificationSettings = JSON.parse(localStorageValue)
      return localNotificationSettings.settings.notificationsSettings.item
    }

    return JSON.parse(localStorageValue)
  } catch (e) {
    // this will return null
  }
  return null
}

export const getNotificationSettings = (): NotificationSettings => {
  const localStorageNotificationSettings =
    getNotificationSettingsFromLocalStorage(LocalStorageKeys.notificationSettings) ??
    getNotificationSettingsFromLocalStorage(LocalStorageKeys.jivewebNotificationSettings) ??
    {}
  return Object.assign({ ...notificationSettingsInitialState }, localStorageNotificationSettings)
}

export { NotificationSettingsNamespace, NotificationSettingsEvents, updateNotificationSettings } from './events'
export type { NotificationSettings } from './models'
