import { getShellApiInstance } from '../../common/shell-api-helpers'
import { isMainWindow } from '../container/windows-management'
import type { CommandMixin, Commands } from './commands'
import { command } from './commands'

/*
 * This mapped type allows us to restrict provided interfaces to only the functions.
 */
type FunctionKeysOnly<T> = {
  // eslint-disable-next-line @typescript-eslint/ban-types
  readonly [K in keyof T]: T[K] extends Function ? K : never
}[keyof T]

/*
 * This mapped type allows us to extract compatible command interface functions.
 */
type ExtractFunction<T> = Pick<T, FunctionKeysOnly<T>>

/*
 * This one augments every command with the functions to manipulate its handlers.
 */
type MapToCommands<T extends ExtractFunction<T>> = {
  readonly [K in FunctionKeysOnly<T>]: CommandMixin<Parameters<T[K]>, ReturnType<T[K]> | undefined>
}

// eslint-disable-next-line @typescript-eslint/ban-types
export interface NamespaceDef<CommandDict = {}> {
  readonly commands?: CommandDict
}

export interface Namespace<NamespaceDefs extends NamespaceDef> {
  readonly commands: MapToCommands<NamespaceDefs['commands']>
}

const getNamespaceIdentifier = (namespace: string | symbol) => {
  if (typeof namespace === 'symbol') {
    return namespace.toString()
  }
  return namespace
}

class NamespaceManager {
  private constructor() { }
  private static instance: NamespaceManager
  static getInstance(): NamespaceManager {
    if (!NamespaceManager.instance) {
      NamespaceManager.instance = new NamespaceManager()
    }
    return NamespaceManager.instance
  }

  private readonly namespaceRegistry = new Map<string | symbol, Namespace<NamespaceDef>>()


  /*
   * A namespace exists as soon as anyone either retrieves or adds to it for the first time.
   * The type parameter allows experiences to publish the type for their commands for type safety.
   */
  retrieve<NamespaceDefs extends NamespaceDef>(namespace: string | symbol): Namespace<NamespaceDefs> {
    if (!this.namespaceRegistry.has(namespace)) {
      this.namespaceRegistry.set(namespace, {
        commands: this.createCommands(getNamespaceIdentifier(namespace)),
      } as Namespace<NamespaceDef>)
    }
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return this.namespaceRegistry.get(namespace)!
  }

  private createCommands(namespace: string | symbol) {
    const commands = {}
    const proxyHandler: ProxyHandler<Commands> = {
      get: (target, commandName: string) => {
        if (!target[commandName]) {
          target[commandName] = command(getNamespaceIdentifier(namespace), commandName)
        }
        return target[commandName]
      },
    }
    return new Proxy(commands, proxyHandler)
  }
}

export type namespaces = NamespaceManager

export const getNamespaces = () => (isMainWindow() ? NamespaceManager.getInstance() : getShellApiInstance().namespaces)
