import type { CSSResult } from 'lit-element'
import { LitElement, html, property } from 'lit-element'
import { unsafeSVG } from 'lit-html/directives/unsafe-svg'

import { t } from '../../directives/translate'
import style from './unauthenticated-error.styles.scss'
import { SVG_ALERT_OUTLINED } from '@getgo/chameleon-icons'

export class GoToUnauthenticatedError extends LitElement {
  static readonly tagName = 'goto-unauthenticated-error'

  @property() login!: () => void
  @property() support!: () => void

  static get styles(): CSSResult {
    return style
  }

  render() {
    return html`
      <topbar-logo class="standalone"></topbar-logo>
      <chameleon-empty-state size="medium">
        <chameleon-svg class="danger-icon" slot="icon">${unsafeSVG(SVG_ALERT_OUTLINED)}</chameleon-svg>
        <chameleon-typography variant="heading-medium" tag="h1" slot="title">
          ${t('Looks like something went wrong')}
        </chameleon-typography>
        ${t('Please double-check your login details and try again.')}
        <div slot="actions">
          <chameleon-button size="small" @click=${this.login}> ${t('Back to login')} </chameleon-button>
          <chameleon-button size="small" @click=${this.support} variant="tertiary">
            ${t('Get support')}
          </chameleon-button>
        </div>
      </chameleon-empty-state>
    `
  }
}

declare global {
  interface HTMLElementTagNameMap {
    readonly [GoToUnauthenticatedError.tagName]: GoToUnauthenticatedError
  }
}
