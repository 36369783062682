import { html } from 'lit-element'
import { repeat } from 'lit-html/directives/repeat'
import type { DialogComponent } from '@getgo/chameleon-web'

import { getEventBus } from '../../../services/namespaces/event-bus'
import type {
  ModalNotification,
  ModalNotificationActions,
  DisplayNotificationEvents,
} from '../../../services/display-notification'
import { DisplayNotificationsNamespace } from '../../../services/display-notification'
import { AbstractNotificationLitElement } from '../model'
import { ifDefined } from 'lit-html/directives/if-defined'
import modalSettingsStyles from './modal-notification.styles.scss'
import { t } from '../../../directives/translate'

export class GoToModalNotification extends AbstractNotificationLitElement<ModalNotification> {
  static readonly tagName = 'goto-modal-notification'

  private get dialog() {
    return this.shadowRoot?.querySelector('chameleon-dialog') as unknown as DialogComponent
  }

  protected setupEventHandlers(): void {
    const eventBus = getEventBus()
    const { modal, removeModal } = eventBus.subscribeTo<
      typeof DisplayNotificationsNamespace,
      typeof DisplayNotificationEvents
    >(DisplayNotificationsNamespace)

    modal.on(this.addNotification)
    removeModal.on(this.removeNotification)

    this.unsubscribeFunctions.push(() => {
      modal.removeListener(this.addNotification)
      removeModal.removeListener(this.removeNotification)
    })
  }
  static get styles() {
    return modalSettingsStyles
  }

  protected addNotification = (data: ModalNotification): void => {
    // TODO: Make sure ONLY ONE at a time
    super.addNotification(data)
    this.eitherShowOrClose()
  }

  protected removeNotification = (): void => {
    // ONLY ONE at a time
    if (this.notifications.length > 0) {
      this.notifications.splice(0, 1)
      this.requestUpdate()
    }
  }

  private eitherShowOrClose() {
    try {
      if (this.notifications.length > 0) {
        this.dialog?.show()
      } else {
        this.dialog?.close()
      }
    } catch {
      /* nothing */
    }
  }

  private readonly renderButton = ({ label, handler, variant, size }: ModalNotificationActions) =>
    html`<chameleon-button variant=${ifDefined(variant)} size=${ifDefined(size)} @click=${handler}>
      ${label}
    </chameleon-button>`

  private handleClosedDialogEvent() {
    const data = this.notifications[0]
    if (data) {
      this.removeNotification(/* ONLY ONE at a time */)
      this.eitherShowOrClose()
      this.requestUpdate()
    }
  }

  render() {
    // Display one modal at a time
    const notification = this.notifications[0]

    if (!notification) {
      this.eitherShowOrClose()
    }

    const title = notification?.title
    const content = notification?.content
    const closable = notification?.closable
    const actions: readonly ModalNotificationActions[] = notification?.actions ?? []

    return html`<chameleon-dialog
      ?open=${false}
      @closed=${this.handleClosedDialogEvent}
      ?closable=${closable}
      close-button-label=${t('Close dialog')}
    >
      <h2 slot="title">${title}</h2>
      <div class="content">
        <p>${content}</p>
      </div>
      <div slot="actions">${repeat(actions, action => action.label, this.renderButton)}</div>
    </chameleon-dialog>`
  }
}

declare global {
  interface HTMLElementTagNameMap {
    readonly [GoToModalNotification.tagName]: GoToModalNotification
  }
}
