import { getShellLogger } from '../common/logger'

export const APP_INIT_MARK = 'app init'
export const SHELL_START_MARK = 'shell start'
export const SHELL_DONE_MARK = 'shell done'

export const measureLoadingTime = (name: string, startMarker: string, doneMarker: string) => () => {
  try {
    return performance.measure(name, startMarker, doneMarker)
  } catch (e) {
    getShellLogger().error(`${name} - error in the performance calculation for mixpanel`, e)
  }
}

export const measureShellLoadingTime = measureLoadingTime('shell_shellStartToDone', SHELL_START_MARK, SHELL_DONE_MARK)

type Measure = { readonly name: string; readonly start: string | undefined; readonly end: string }

const measurements: readonly Measure[] = [
  { name: 'shell_init', start: undefined, end: APP_INIT_MARK },
  { name: 'shell_appToShell', start: APP_INIT_MARK, end: SHELL_START_MARK },
]

export const logPerformance = () => {
  measurements.forEach(({ name, start, end }) => {
    try {
      performance.measure(name, start, end)
    } catch (e) {
      getShellLogger().error(
        `Performance Marker ${start} does not exist. Performance measurements will not be correct.`,
      )
    }
  })
  const measures: { [key: string]: { duration: number; startTime: number } } = {}
  try {
    // get all the measurements made so far
    performance.getEntriesByType('measure').reduce((acc, perf) => {
      const { name, duration, startTime } = perf
      acc[name] = { duration, startTime }
      return acc
    }, measures)

    getShellLogger().log({ msg: 'GoTo Shell Performance Measurements', ...measures, startingURL: location.href })
  } catch (error) {
    getShellLogger().error({ msg: `Error while measuring GoTo Shell Performance: ${error}` })
  }
}
