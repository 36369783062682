/* eslint-disable  @typescript-eslint/no-explicit-any */
import {
  Environment,
  getCurrentEnvironment,
  GoToExperienceConfig,
  loadScript,
  NotificationChannelNamespace,
  NotificationChannelEvents,
} from '@goto/shell'
import { getLocationPathname, reloadPage } from './dom-helpers'
import { isInPathname } from './experience-helpers'
import { getFeatureFlag } from './shell-helpers'
import { loadContactCenterAgentService } from './contact-center-agent-experience-helpers'

type JiveWebExperience = GoToExperienceConfig['app']
type JiveWebLoader = JiveWebExperience | { default: () => Promise<JiveWebExperience> }

let jiveWebExperiencePromise: Promise<JiveWebExperience> | null = null

export const GTC_VERSION_FLAG_NAME = 'gtc-version'
const RELOAD_APP_ERROR_PAGE_ON_RECONNECT_FLAG = 'reload-app-error-page-on-reconnect'
const MAX_RELOAD_ATTEMPTS = 2
const MAX_RELOAD_PERIOD = 60000 * 60 // 1 hour

export const loadGtcDependencies = async () => {
  try {
    await Promise.all([
      loadScript('https://cdnjs.cloudflare.com/ajax/libs/store.js/1.3.20/store.min.js'),
      loadScript('https://cdnjs.cloudflare.com/ajax/libs/localforage/1.4.2/localforage.min.js'),
    ])
  } catch (e) {
    console.warn('Cannot load GTC polyfills')
  }
}

const loadJivewebIndexJS = async (gtcPath: string) => {
  const module = await loadScript<Promise<JiveWebLoader>>(gtcPath, {
    resolveWithGlobal: '@jive/web', // matches the library of jiveweb's webpack.lib.config
  })
  // To handle migration to extension API
  // Experience entry point could be a script which dynamically import the GTC SPA object
  // In that case Experience only expose a default function
  if ('default' in module && typeof module.default === 'function') {
    const app: JiveWebExperience = await module.default()
    return app
  }
  return module as JiveWebExperience
}

const isInBackgroundMode = () => {
  const loadGTCInBackgroundPathname = [
    '/meetings',
    '/shell-settings',
    '/contacts',
    '/messaging',
    '/trainings',
    '/webinars',
    '/contact-center',
    ...['/contact-center/agent', '/contact-center/configuration', '/contact-center/supervisor', '/contact-center/quality-management'],
    ...['/contact-center-agent', '/contact-center-configuration', '/contact-center-supervisor', '/contact-center-quality-management'],
    '/cc-dashboard',
    '/engagements',
    '/onboarding',
    '/inbox',
    '/inbox/shared',
  ]

  return loadGTCInBackgroundPathname.some(loadGTCinBackgroundPath =>
    isInPathname(location.pathname, loadGTCinBackgroundPath),
  )
}

export const removeGTCStylesIfRequired = () => {
  const observerConfig = {
    attributes: false,
    childList: true,
    characterData: true,
    subtree: true,
  }
  const observer = new MutationObserver(mutations => {
    mutations.forEach(mutation => {
      if (mutation.type === 'childList') {
        const gtcStyles = document.querySelectorAll<HTMLStyleElement>('.gtc-styles')
        if (gtcStyles.length > 0 && isInBackgroundMode()) {
          gtcStyles.forEach(styleElement => (styleElement.media = 'not all'))
          observer.disconnect()
        }
      }
    })
  })
  observer.observe(document.head, observerConfig)
}

const SINGLE_SPA_APP_CHANGE = 'single-spa:app-change'
export const manageJiveWebLifecycle = () => {
  window.addEventListener(SINGLE_SPA_APP_CHANGE, () => {
    if (isInBackgroundMode()) {
      jiveWebExperiencePromise?.then(app => {
        if ('unmount' in app && typeof app.unmount === 'function') {
          app.unmount(undefined as any)
        }
      })
    } else {
      jiveWebExperiencePromise?.then(app => {
        if ('mount' in app && typeof app.mount === 'function') {
          app.mount(undefined as any)
        }
      })
    }
  })
}

export const loadJiveWebExperience = () => {
  const path = getLocationPathname().toLowerCase().split('/recording/')

  if (Array.isArray(path) && path[0] === '/shared' && path[1]) {
    const baseUrl = getContentBaseUrl()
    window.location.replace(`${baseUrl}/${path[1]}`)
    return null
  }

  if (jiveWebExperiencePromise !== null) {
    return jiveWebExperiencePromise
  }

  const gtcVersion = getFeatureFlag(GTC_VERSION_FLAG_NAME, 'latest')
  let gtcPath = '/experiences/jiveweb/index.js'
  if (gtcVersion !== 'latest') {
    gtcPath = `/experiences/jiveweb/${gtcVersion}/index.js`
  }

  jiveWebExperiencePromise = loadJivewebIndexJS(gtcPath)
  return jiveWebExperiencePromise
}

/**
 * GTC extension helper
 * This function is specific for GTC extension and must be an exception to handle legacy issues
 */
export const setupGtcExtensionPolyfillsAndServices = async () => {
  await loadGtcDependencies()
  removeGTCStylesIfRequired()
  manageJiveWebLifecycle()
  await loadContactCenterAgentService()
}

const unguardedBaseRoutes = ['/meet', '/shared/recording']

export const listUnguardedRoutes = (pathname = '') => {
  // extract any path prefix (ex: feature branch)
  const unguardedRoutesRegex = new RegExp(`(.*)(${unguardedBaseRoutes.join('|')})`, 'i')

  const routePrefix = unguardedRoutesRegex.exec(pathname)?.[1] ?? ''

  // prepend prefix and remove any double slash
  return unguardedBaseRoutes.map(route => `${routePrefix}${route}`.replace(/\/+/g, '/'))
}

const getContentBaseUrl = () => {
  switch (getCurrentEnvironment()) {
    case Environment.ED:
      return 'https://transcriptsed.g2m.test.expertcity.com/#/s'
    case Environment.RC:
      return 'https://transcriptsrc.g2m.test.expertcity.com/#/s'
    case Environment.STAGING:
      return 'https://transcriptsstage.g2m.test.expertcity.com/#/s'
    case Environment.NIGHTLY:
    case Environment.PROD:
    default:
      return 'https://transcripts.gotomeeting.com/#/s'
  }
}

const canReloadApplication = () => {
  const reloadFeatureFlagEnabled = getFeatureFlag(RELOAD_APP_ERROR_PAGE_ON_RECONNECT_FLAG, false)
  if (!reloadFeatureFlagEnabled) {
    return false
  }

  const currentTime = new Date().getTime()
  const lastReloadData = JSON.parse(
    localStorage.getItem('goto-reload-attempts') ?? JSON.stringify({ lastReloadTimestamp: 0, reloadAttemptCount: 0 }),
  )

  let reloadAttemptCount = lastReloadData.reloadAttemptCount
  let lastReloadTimestamp = lastReloadData.lastReloadTimestamp

  if (currentTime - lastReloadData.lastReloadTimestamp >= MAX_RELOAD_PERIOD) {
    reloadAttemptCount = 0
    lastReloadTimestamp = currentTime
    localStorage.setItem('goto-reload-attempts', JSON.stringify({ lastReloadTimestamp, reloadAttemptCount }))
  }

  if (lastReloadData.reloadAttemptCount >= MAX_RELOAD_ATTEMPTS) {
    return false
  }

  localStorage.setItem(
    'goto-reload-attempts',
    JSON.stringify({ lastReloadTimestamp, reloadAttemptCount: reloadAttemptCount + 1 }),
  )
  return true
}

export const attemptAppReloadWhenOnline = () => {
  const notificationChannelEvents = window.shell.eventBus.subscribeTo<
    typeof NotificationChannelNamespace,
    typeof NotificationChannelEvents
  >(NotificationChannelNamespace)

  const connectedHandler = () => {
    if (canReloadApplication()) {
      notificationChannelEvents.connected.removeListener(connectedHandler)
      reloadPage()
    }
  }

  notificationChannelEvents.connected.addListener(connectedHandler)

  window.addEventListener('unload', () => {
    notificationChannelEvents.connected.removeListener(connectedHandler)
  })
}
