import {
  GOTO_ROOT_ID,
  GOTO_TOPBAR_CONTAINER_ID,
  GOTO_NAVIGATION_ID,
  GOTO_CONTENT_ID,
  GOTO_BANNER_NOTIFICATION_ID,
  GOTO_SNACKBAR_NOTIFICATION_ID,
  GOTO_MODAL_NOTIFICATION_ID,
  CHAMELEON_THEME_PROVIDER,
  CHAMELEON_LAUNCH_SCREEN,
  GOTO_SPECIFIC_AREA,
  GOTO_DIALOG_CONTAINER_ID,
  GOTO_CRITICAL_BANNER_CONTAINER_ID,
} from './container'
import { getShellLogger } from './logger'

const addFavicon = (faviconPath: string) => {
  const document = getDocument()
  const iconElement = document.createElement('link')
  iconElement.rel = 'icon'
  iconElement.type = 'image/x-icon'
  iconElement.href = faviconPath
  iconElement.id = 'dynamic-favicon'
  document.head.prepend(iconElement)
}

const removeFavicon = () => {
  const favicon = document.getElementById('dynamic-favicon')
  if (favicon) {
    document.head.removeChild(favicon)
  }
}

export const setFavicon = (faviconPath: string) => {
  removeFavicon()
  addFavicon(faviconPath)
}

const createElement = (tagName: string, id?: string, classes?: readonly string[]): HTMLElement => {
  const element = getDocument().createElement(tagName)

  if (id) {
    element.id = id
  }

  if (classes) {
    element.classList.add(...classes)
  }

  return element
}

export const getLocationHREF = () => getWindow().location.href
export const getLocationOrigin = () => getWindow().location.origin
export const getLocationPathname = () => getWindow().location.pathname
export const getLocationHash = () => getWindow().location.hash
export const getLocationSearch = () => getWindow().location.search
export const getLocationSearchParamsByName = (name: string) => {
  const searchParams = new URLSearchParams(getLocationSearch())
  return searchParams.get(name)
}
export const getLocationHostname = () => getWindow().location.hostname
export const getLocationProtocol = () => getWindow().location.protocol

/**
 * Retrieves a base64-encoded object from the url.
 * @param name name of url part preceding the base64 encoded object.  i.e. `my-name` for `http://www.goto.com/my-name/eyJxIjoieW91IGFyZSBjdXJpb3VzIn0=/page`
 * if no name is specified, last part of url will be used as for `http://www.goto.com/my-name/eyJxIjoieW91IGFyZSBjdXJpb3VzIn0=`
 * @returns object that was encoded in url in base64
 */
// eslint-disable-next-line @typescript-eslint/ban-types
export const getLocationObject = <T extends Object>(name?: string): T | undefined => {
  try {
    const path = getLocationPathname()
    const parts = path.split('/')
    const index = name ? parts.indexOf(name) + 1 : parts.length - 1
    const encodedBase64 = parts[index]
    const base64 = decodeBase64FromUrl(encodedBase64)
    const str = decodeURIComponent(atob(base64))
    const result = JSON.parse(str)
    return result
  } catch {
    return undefined
  }
}

export const encodeBase64ForUrl = (base64: string) => {
  const b64Chars: { readonly [index: string]: string } = { '+': '-', '/': '_', '=': '' }
  return base64.replace(/[+/=]/g, (m: string) => b64Chars[m])
}

export const decodeBase64FromUrl = (urlBase64: string) => {
  const b64Chars: { readonly [index: string]: string } = { '-': '+', _: '/' }
  return urlBase64.replace(/[-_]/g, (m: string) => b64Chars[m])
}

/**
 * Takes an object and encodes it to a url safe base64 string.
 * The encoder method for the decoder `decodeUrlSafeBase64ToObject()`.
 * @param obj
 * @returns a url safe base64 string
 */
export const encodeObjectToUrlSafeBase64 = <T>(obj: T) => {
  const str = JSON.stringify(obj)
  const base64 = btoa(encodeURIComponent(str))
  return encodeBase64ForUrl(base64)
}

/**
 * Takes a url safe base64 string and decodes it to an object.
 * The decoder method for the encoder `encodeObjectToUrlSafeBase64()`.
 * @param urlSafeBase64
 * @returns an object
 */
export const decodeUrlSafeBase64ToObject = <T>(urlSafeBase64: string): T => {
  const base64 = decodeBase64FromUrl(urlSafeBase64)
  const str = decodeURIComponent(atob(base64))
  return JSON.parse(str)
}

/**
 * Finds a base64 encoded url parameter and decodes it to an object.
 * @param paramName the name of the url parameter
 * @returns an object
 */
export const findAndDecodeBase64UrlParam = (paramName: string) => {
  const searchParams = new URLSearchParams(getLocationSearch())
  const base64EncodedValue = searchParams.get(paramName)

  if (!base64EncodedValue) {
    return
  } else {
    return decodeUrlSafeBase64ToObject(base64EncodedValue)
  }
}

export const setLocationHref = (url: string) => {
  getWindow().location.href = url
}

export const postMessageOnMainWindow = <T>(message: T, targetOrigin: string) =>
  getWindow().top?.postMessage(message, targetOrigin)

export const createGotoRootElement = () => createElement('goto-shell-controller', GOTO_ROOT_ID)
export const createTopBarContainer = () => createElement('header', GOTO_TOPBAR_CONTAINER_ID)
export const createInspectorPanelContainer = () => createElement('goto-inspector-panel')
export const createNavigationElement = () => createElement('div', GOTO_NAVIGATION_ID)
export const createMainElement = () => createElement('main', undefined, ['goto-container'])
// Hiden until we can get the branding FeatureFlag value
export const createChameleonLauncherScreenElement = () => {
  const elem = createElement(CHAMELEON_LAUNCH_SCREEN)
  return elem
}

export const createBannerNotificationElement = () => createElement('div', GOTO_BANNER_NOTIFICATION_ID)
export const createCriticalBannerContainerElement = () => createElement('div', GOTO_CRITICAL_BANNER_CONTAINER_ID)
export const createTrialBannerElement = () => createElement('goto-trial-banner')
export const createWindowsSupportBannerElement = () => createElement('goto-windows-support')
export const createMacOSSupportBannerElement = () => createElement('goto-macos-support')
export const createBlockedExtensionsBannerElement = () => createElement('goto-blocked-extensions-banner')
export const createExperienceContentElement = () => createElement('div', GOTO_CONTENT_ID)
export const createSnackbarNotificationElement = () => createElement('div', GOTO_SNACKBAR_NOTIFICATION_ID)
export const createModalNotificationElement = () => createElement('div', GOTO_MODAL_NOTIFICATION_ID)
export const createDialogContainer = () => createElement('div', GOTO_DIALOG_CONTAINER_ID)

export const createChameleonThemeProviderElement = () => createElement(CHAMELEON_THEME_PROVIDER)
export const createSpecificAreaElement = () => createElement('div', GOTO_SPECIFIC_AREA)

export const getWindow = (): Window => window
export const getDocument = (): Document => document

export const getDocumentReferrer = () => getDocument().referrer
export const getDocumentHead = () => getDocument().head

export const getNavigator = () => getWindow().navigator
export const getNavigatorUserAgent = () => getNavigator().userAgent

/**
 * Assigns the location to the specified url.
 * If the current location matches the destination url and the url contains a hash, a force reload is applied.
 * @param url destination url
 */
export const navigateToExternal = (url: string) => {
  const location = getWindow().location
  try {
    const destination = convertToUrl(url)
    const forceReload =
      location.origin === destination.origin && location.pathname === destination.pathname && !!destination.hash
    getWindow().location.assign(url)
    if (forceReload) {
      getWindow().location.reload()
    }
  } catch (e) {
    getShellLogger().error(`Improper URL passed to the function: ${url}, ${e}`)
  }
}

const convertToUrl = (url: string) => {
  try {
    return new URL(url)
  } catch {
    if (url.startsWith('/')) {
      return new URL(url, getLocationOrigin())
    }
    throw new Error('Invalid URL')
  }
}

export const getActiveUrlPath = () => {
  const pathname = getLocationPathname() ?? ''
  const search = getLocationSearch() ?? ''
  const hash = getLocationHash() ?? ''
  return `${pathname}${search}${hash}`
}

export const getFromLocalStorage = (key: string) => getWindow().localStorage.getItem(key)
export const setToLocalStorage = (key: string, value: string) => getWindow().localStorage.setItem(key, value)
export const removeFromLocalStorage = (key: string) => getWindow().localStorage.removeItem(key)

export const popFromLocalStorage = (key: string) => {
  const value = getFromLocalStorage(key)
  removeFromLocalStorage(key)
  return value
}
export const setToSessionStorage = (key: string, value: string) => getWindow().sessionStorage.setItem(key, value)
export const getFromSessionStorage = (key: string) => getWindow().sessionStorage.getItem(key)
export const removeFromSessionStorage = (key: string) => getWindow().sessionStorage.removeItem(key)
export const popFromSessionStorage = (key: string) => {
  const value = getFromSessionStorage(key)
  removeFromSessionStorage(key)
  return value
}

export const setTimeout = (callback: () => void, intervalMs: number) => getWindow().setTimeout(callback, intervalMs)
export const clearTimeout = (handler: number) => getWindow().clearTimeout(handler)
export const setInterval = (callback: () => void, intervalMs: number) => getWindow().setInterval(callback, intervalMs)
export const clearInterval = (handler: number) => getWindow().clearInterval(handler)

/*
 * This will open the URL in a new tab or in the case of electron container in the browser
 */
export const openNewTabFor = (url: string) => getWindow().open(url, '_blank', 'noreferrer')

export const openNewWindowFor = (url: string, newWindowOptions: NewWindowOptions) =>
  getWindow().open(
    url,
    '_blank',
    `rel=noreferrer,
    height=${newWindowOptions.height},
    width=${newWindowOptions.width},
    left=${newWindowOptions.left},
    top=${newWindowOptions.top}`,
  )

export const reloadPage = () => {
  getWindow().location.reload()
}

const observerConfig = {
  attributes: false,
  childList: true,
  characterData: true,
  subtree: true,
}

export const waitForElement = (
  selectorOrPredicate: string | ((node: Node) => boolean),
  parent: Element,
): Promise<Element> => {
  if (typeof selectorOrPredicate === 'string') {
    return waitForElementWithSelector(selectorOrPredicate as string, parent)
  }
  return waitForElementWithPredicate(selectorOrPredicate as (node: Node) => boolean, parent)
}

const waitForElementWithPredicate = (predicate: (node: Node) => boolean, parent: Element): Promise<Element> =>
  new Promise(resolve => {
    const matches = Array.from(parent.childNodes).filter(node => node.nodeType === Node.ELEMENT_NODE && predicate(node))
    if (matches.length) {
      resolve(matches[0] as Element)
    } else {
      const observer = new MutationObserver((mutations, obs) => {
        mutations.forEach(mutation => {
          mutation.addedNodes.forEach(node => {
            if (node.nodeType === Node.ELEMENT_NODE && predicate(node)) {
              obs.disconnect()
              resolve(node as Element)
            }
          })
        })
      })
      observer.observe(parent, observerConfig)
    }
  })

const waitForElementWithSelector = (selector: string, parent: Element): Promise<Element> =>
  new Promise(resolve => {
    const element = parent.querySelectorAll(selector)
    if (element.length) {
      resolve(element.item(0) as Element)
    } else {
      const observer = new MutationObserver((mutations, obs) => {
        if (mutations.some(mutation => mutation.addedNodes.length)) {
          const el = parent.querySelectorAll(selector)
          if (el.length) {
            obs.disconnect()
            resolve(el.item(0) as Element)
          }
        }
      })
      observer.observe(parent, observerConfig)
    }
  })

export const isInIframe = () => window.self !== window.top

export interface NewWindowOptions {
  readonly height: string
  readonly width: string
  readonly left: string
  readonly top: string
}
