import { LitElement, html, state } from 'lit-element'

import { getShellLogger } from '../../common/logger'
import { asI18nLocale, I18N_LOCALES } from '../../common/translate-helpers/locales'
import type { I18nLocale } from '../../common/translate-helpers/types'
import { t } from '../../directives/translate'
import { getCurrentLocale, localeChangeBegin } from '../../services/i18n'
import { updateUserProfileLocale } from '../../services/identity/userProfile'

import elementStyles from './language-settings.styles.scss'

export class LanguageSettingsElement extends LitElement {
  static readonly tagName = 'goto-language-settings'

  @state() private _locale = getCurrentLocale()
  static styles = elementStyles

  render() {
    return html`
      <div class="settings-page">
        <chameleon-typography class="page-header" tag="h1" variant="heading-large">
          ${t('Language')}
        </chameleon-typography>
        <div class="page-content">
          <chameleon-typography class="header-description" tag="p" variant="body-medium">
            ${t('Pick what language you see app-wide.')}
          </chameleon-typography>
          <goto-language-select
            @change=${this.handleLanguageSelected}
            .selectedLocale=${this._locale}
            .locales=${[...I18N_LOCALES]}
          ></goto-language-select>
        </div>
      </div>
    `
  }

  private async handleLanguageSelected(event: CustomEvent<I18nLocale>) {
    const locale = asI18nLocale(event.detail)
    try {
      if (locale) {
        await updateUserProfileLocale(locale)
        this._locale = locale
        localeChangeBegin(locale)
      }
    } catch (err) {
      getShellLogger().error(`Unable to update user profile locale '${locale}'`, err)
    }
  }
}

declare global {
  interface HTMLElementTagNameMap {
    readonly [LanguageSettingsElement.tagName]: LanguageSettingsElement
  }
}
