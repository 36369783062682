import { getAvatarUrls } from '../services/avatar'
import { getIdFromUserProfile } from '../services/identity/userProfile'
import type { ShellUserInfo } from './models'

export const getAvatarData = async (user: ShellUserInfo) => {
  try {
    // if we fail for any reason, we should continue
    return await getAvatarUrls(getIdFromUserProfile(user.key))
  } catch (error) {
    return undefined
  }
}
