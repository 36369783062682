import { SVG_INFO_OUTLINED } from '@getgo/chameleon-icons'
import { html, LitElement, state } from 'lit-element'
import { t } from '../../directives/translate'
import { getShellApiInstance } from '../../common/shell-api-helpers'
import styles from './shell-landing.styles.scss'
import { nothing } from 'lit-html'
import { unsafeSVG } from 'lit-html/directives/unsafe-svg'
import { getInflightRequest } from '../../services/auth/utils'
import { isContainer } from '../../services/container/helpers'
import { isMobileUser } from '../../common/user-agent-helpers'
import { getExternalInterface } from '../../services/external-interface'
import { getTranslation } from '../../services/i18n/i18nUtils'
import { getStoreCurrentRoute } from '../../common/store-current-route'
import { ROOT_ROUTE } from '../../common/routes'

export class ShellLanding extends LitElement {
  static readonly tagName = 'goto-shell-landing'
  @state() private hasStartedSignIn = false

  static get styles() {
    return styles
  }

  private login() {
    this.hasStartedSignIn = true
    const inflightPathname = getInflightRequest() ?? getStoreCurrentRoute() ?? ROOT_ROUTE
    getShellApiInstance().auth.login({ inflightPathname, restartFlow: true })
  }

  private renderSignInStartedInfoMessage() {
    return this.hasStartedSignIn && isContainer()
      ? html`
          <div class="message-info-container">
            <chameleon-svg>${unsafeSVG(SVG_INFO_OUTLINED)}</chameleon-svg>
            <chameleon-typography variant="caption-medium">
              ${t('Sign in using the last tab we opened in your browser.')}
            </chameleon-typography>
          </div>
        `
      : nothing
  }

  private renderMobileAppDownloadPrompt() {
    return isMobileUser()
      ? html`
          <chameleon-typography variant="body-large" class="download-description">
            ${t('Get the most out of GoTo on the mobile app.')} </chameleon-typography
          ><goto-mobile-badge></goto-mobile-badge>
        `
      : nothing
  }

  private renderMeetingIdInput() {
    return getExternalInterface().isIntegration && isMobileUser()
      ? nothing
      : html`<goto-meeting-id-input></goto-meeting-id-input>`
  }

  private getDescriptionText() {
    return getExternalInterface().isIntegration && isMobileUser()
      ? getTranslation('Sign in to see your calls.')
      : getTranslation('Sign in to see your meetings and more or enter session ID or name to join a meeting.')
  }

  render() {
    return html` <div class="landing-container">
      <div class="landing-content">
        <chameleon-goto-logo></chameleon-goto-logo>
        <chameleon-typography variant="heading-large" class="container-title">
          ${t('Tools to help you work together better')}
        </chameleon-typography>
        <chameleon-typography variant="body-large" class="container-description">
          ${this.getDescriptionText()}
        </chameleon-typography>
        <div class="user-action-container">
          <chameleon-button id="sign-in-to-goto" variant="primary" size="xlarge" @click=${this.login}>
            ${t('Sign in to GoTo')}
          </chameleon-button>
          <chameleon-typography variant="body-large" class="or-text">${t('Or')}</chameleon-typography>
          ${this.renderMeetingIdInput()}
        </div>
        ${this.renderSignInStartedInfoMessage()} ${this.renderMobileAppDownloadPrompt()}
      </div>
    </div>`
  }
}

declare global {
  interface HTMLElementTagNameMap {
    readonly [ShellLanding.tagName]: ShellLanding
  }
}
