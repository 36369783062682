import { environment } from '../../environments'
import { getExternalInterface } from '../external-interface'
import { type IShellPresenceService } from './models'
import { ShellPresenceService } from './presence'
import { ShellPresenceServiceCompanionAdapter } from './presence-service-companion-adapter'
import { ShellPresenceServiceIntegrationAdapter } from './presence-service-integration-adapter'
import { PresenceService } from './service'

export const createPresenceService = (notificationChannelId: string): IShellPresenceService => {
  if (getExternalInterface().isIntegration) {
    return new ShellPresenceServiceIntegrationAdapter()
  }

  const presenceService = new PresenceService(environment().presenceServiceUrl)
  const shellPresenceService = new ShellPresenceService(presenceService, notificationChannelId)

  if (getExternalInterface().isCompanion) {
    return new ShellPresenceServiceCompanionAdapter(shellPresenceService)
  }

  return shellPresenceService
}
