import type { PropertyValues } from 'lit-element'
import { query, html, property, state } from 'lit-element'
import type { TemplateResult } from 'lit-html'
import { nothing } from 'lit-html'
import { ifDefined } from 'lit-html/directives/if-defined'
import { unsafeSVG } from 'lit-html/directives/unsafe-svg'
import { SVG_PERSON_OUTLINED } from '@getgo/chameleon-icons'
import { type GotoAvatarComponent, GOTO_AVATAR } from '@goto/shell-common'

import styles from './avatar.styles.scss'
import { getAvatarUrls } from '../../services/avatar'

import {
  type AvatarVariantV2,
  IconBadgeVariant,
  AvatarType,
  type AvatarSize,
  type AvatarSizeV2,
  type AvatarVariant,
} from '@getgo/chameleon-core'
import { noop } from '../../common/helpers'
import { getDocument } from '../../common/dom-helpers'
import { getAvatarColorTokenFromString } from './avatar-color-helper'
import type { AvatarComponent } from '@getgo/chameleon-web'
import { ShellElement } from '../../common/shell-element'

/**
 * @see react/avatar/avatar.shell.tsx
 */
export class GoToAvatar extends ShellElement implements GotoAvatarComponent {
  static readonly tagName = GOTO_AVATAR
  @query('chameleon-avatar-v2') chameleonAvatar: AvatarComponent | undefined
  @state() protected avatar: HTMLImageElement | string | TemplateResult | undefined
  @state() private avatarColorToken = '--goto-avatar-color-primary'
  @state() private v2variant?: AvatarVariantV2
  @state() private v2size?: AvatarSizeV2
  @property({ type: Boolean }) showPresence = false
  @property({ type: Boolean }) hidePresenceTooltip = false
  @property({ type: Boolean }) usePresenceSnapshot = false
  @property({ type: String }) size?: AvatarSizeV2 | AvatarSize
  @property({ type: String }) type?: AvatarType
  @property({ type: String }) givenName = ''
  @property({ type: String }) familyName = ''
  @property({ type: String }) profileImageUrl = ''
  @property({ type: String }) externalUserKey?: string = undefined
  @property({ type: String }) icon = ''
  @property({ type: String }) emailAddress = ''
  @property({ type: String }) variant?: AvatarVariant | AvatarVariantV2
  @property({ type: String }) iconBadge?: IconBadgeVariant
  @property({ attribute: false }) onClick = noop
  @property({ type: String }) label? = `${this.givenName} ${this.familyName}`
  // can be removed after all teams have migrated to V2
  @property({ type: Boolean }) useChameleonAvatarV2 = false

  static get styles() {
    return styles
  }

  connectedCallback() {
    super.connectedCallback()
    this.setColorToken()
    this.setBackgroundColor()
    this.getAvatar()
  }

  firstUpdated() {
    this.setAvatarVersion()
  }

  update(changedProperties: PropertyValues) {
    if (!changedProperties.get('avatar')) {
      this.setAvatarVersion()
      this.getAvatar()
    }
    super.update(changedProperties)
  }

  protected createRenderRoot(): Element | ShadowRoot {
    return this.attachShadow({ mode: 'open', delegatesFocus: true })
  }

  private getFirstChar(name: string) {
    return name?.trim().charAt(0)
  }

  private setBackgroundColor() {
    if (this.variant === 'primary') {
      // necessary due to a race condition in the setting of the primary background color token in chameleon
      setTimeout(() =>
        this.chameleonAvatar?.style.setProperty('--goto-avatar-bg-color', `var(${this.avatarColorToken})`),
      )
    }
  }

  private getInitials() {
    const firstInitial = this.givenName ?? ''
    const lastInitial = this.familyName ?? ''
    return `${this.getFirstChar(firstInitial)}${this.getFirstChar(lastInitial)}`.toUpperCase()
  }

  private setColorToken() {
    const stringForColor = this.externalUserKey
      ? this.externalUserKey
      : this.givenName?.concat(this.familyName) ?? this.familyName

    if (!this.givenName && !this.familyName) {
      this.avatarColorToken = '--goto-avatar-color-primary'
    } else if (stringForColor) {
      this.avatarColorToken = getAvatarColorTokenFromString(stringForColor)
    }
  }

  private getFallbackAvatar() {
    if (this.givenName || this.familyName) {
      return this.getInitials()
    }
    return this.getPersonIconTemplate()
  }

  // This code should be considered temporary until teams migrate from v1 to v2.
  private setAvatarVersion() {
    this.setSizeAndFont()
    this.setVariant()
  }

  private setVariant() {
    // Both v1 and v2 share the same primary and secondary variants.
    // The neutral variant is a legacy variant that only exists on v1 and is v1's default variant. It should no longer be used.
    // If no variant is specified when not on v2, or neutral is specified, we need to use secondary instead which is the closest match.
    if ((!this.useChameleonAvatarV2 && !this.variant) || this.variant === 'neutral') {
      this.v2variant = 'secondary'
    } else {
      this.v2variant = this.variant as AvatarVariantV2
    }
  }

  private setSizeAndFont() {
    if (this.useChameleonAvatarV2) {
      this.v2size = this.size as AvatarSizeV2
    } else {
      const newSize = this.mapSizeToV1Size()
      const newFont = this.mapFontToV1Font()
      this.chameleonAvatar?.setAttribute('style', `width:${newSize}px; height:${newSize}px; font:${newFont}`)
    }
  }

  private mapSizeToV1Size() {
    switch (this.size) {
      case 'xsmall':
        return '24'
      case 'small':
        return '30'
      case 'medium':
        return '36'
      case 'large':
        return '48'
      case 'xlarge':
        return '64'
      case 'xxlarge':
        return '80'
      case 'xxxlarge':
        return '180'
      default:
        return '36'
    }
  }

  private mapFontToV1Font() {
    // Copied from the goto-avatar design tokens https://chameleondesignsystem.com/foundations/design-tokens/ which were used for chameleon-avatar-v1 fonts.
    switch (this.size) {
      case 'xsmall':
        return '700 0.625rem/1rem var(--goto-font-family)'
      case 'small':
        return '700 0.75rem/1.065rem var(--goto-font-family)'
      case 'medium':
        return '700 0.875rem/1.25rem var(--goto-font-family)'
      case 'large':
        return '700 1.25rem/1.5rem var(--goto-font-family)'
      case 'xlarge':
        return '700 1.667rem/1.9337199999999999rem var(--goto-font-family)'
      case 'xxlarge':
        return '700 2.25rem/2.6774999999999998rem var(--goto-font-family)'
      case 'xxxlarge':
        return '700 5.063rem/6.02497rem var(--goto-font-family)'
      default:
        return '700 0.875rem/1.25rem var(--goto-font-family)'
    }
  }

  updated() {
    this.setColorToken()
    this.setBackgroundColor()
  }

  private async getProfileImageUrl(externalUserKey = '', emailAddress?: string) {
    const { urlMedium, isDefault = true } = await getAvatarUrls(externalUserKey, emailAddress)
    if (isDefault) {
      throw 'using default'
    }
    return urlMedium
  }

  /**
   * The goto-avatar should render 1 of 4 different templates depending on the given properties and the user's
   * profile image setting:
   *  - icon tag
   *  - img tag
   *  - initials string
   *  - person icon svg
   *
   * When a profileImageUrl is given:
   *  - if the img successfully loads, render the img tag.
   *  - if the img fails to load, do fallback flow.
   *
   * When externalUserKey or emailAddress is given, and profileImageUrl is omitted:
   *  - if the user has uploaded a custom profile image (isDefault = false) render the img tag.
   *  - if the user is using the default profile image (isDefault = true), do fallback flow.
   *  - if the user has uploaded a custom profile image, but loading the image fails, do fallback flow.
   *
   * Fallback flow:
   *  - if we know the user's given or last name, render the intials string, else proceed to step 2.
   *  - if we know nothing about the user, display the person icon svg.
   */
  private async getAvatar() {
    // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
    if (this.profileImageUrl || this.externalUserKey || this.emailAddress) {
      try {
        const profileImageUrl =
          this.profileImageUrl || (await this.getProfileImageUrl(this.externalUserKey, this.emailAddress))
        this.avatar = this.createImage(profileImageUrl)
      } catch (error) {
        this.avatar = this.getFallbackAvatar()
      }
    } else {
      this.avatar = this.getFallbackAvatar()
    }
  }

  private createImage(url: string) {
    const img = getDocument().createElement('img')
    img.alt = `${this.givenName} ${this.familyName}`
    img.src = url
    img.onerror = () => (this.avatar = this.getFallbackAvatar())

    return img
  }

  private getPersonIconTemplate() {
    return html`<chameleon-svg data-test-person-icon> ${unsafeSVG(SVG_PERSON_OUTLINED)} </chameleon-svg>`
  }

  render() {
    return html`
      <chameleon-avatar-v2
        size=${ifDefined(this.v2size)}
        type=${ifDefined(this.type)}
        @click=${this.onClick}
        data-test="avatar"
        variant=${ifDefined(this.v2variant)}
        label=${ifDefined(this.label)}
      >
        ${this.icon ? html`<chameleon-svg>${unsafeSVG(this.icon)}</chameleon-svg>` : this.avatar}
        ${this.iconBadge
          ? html`<chameleon-icon-badge
              type=${this.iconBadge}
              size="small"
              slot="badge"
              label=${this.iconBadge}
            ></chameleon-icon-badge>`
          : nothing}
        ${this.showPresence && !!this.externalUserKey
          ? html`<goto-presence-indicator
              tabindex="-1"
              slot="presence"
              .externalUserKey=${this.externalUserKey}
              .presenceSnapshot=${this.usePresenceSnapshot}
              .hidePresenceTooltip=${this.hidePresenceTooltip}
            />`
          : nothing}
      </chameleon-avatar-v2>
    `
  }
}

declare global {
  interface HTMLElementTagNameMap {
    readonly [GoToAvatar.tagName]: GoToAvatar
  }
}
