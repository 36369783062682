import { type EmptyStateSize } from '@getgo/chameleon-core'
import { ShellElement } from '../../../common/shell-element'
import searchError from './search-error.styles.scss'
import { html, property } from 'lit-element'
import { PICTOGRAM_ALERT_STATIC } from '@getgo/chameleon-pictograms'
import { t } from '../../../directives/translate'

export interface searchErrorProps {
  readonly size: EmptyStateSize
}

export class SearchError extends ShellElement implements searchErrorProps {
  static readonly tagName = 'goto-search-error'

  @property({ type: String }) size: EmptyStateSize = 'medium'

  static get styles() {
    return searchError
  }

  private firstLineText() {
    if (this.size === 'small') {
      return html`${t('Something went wrong.')}<br />`
    } else {
      return html` <p slot="title">${t('Something went wrong')}</p> `
    }
  }

  render() {
    return html`
      <chameleon-empty-state size=${this.size}>
        <chameleon-svg slot="icon" class="error-pictogram">${PICTOGRAM_ALERT_STATIC}</chameleon-svg>
        ${this.firstLineText()}${t('Go ahead and try again, or try searching from the ')}
        <chameleon-link href="https://app.goto.com/contacts">${t('Contacts page')}</chameleon-link>
      </chameleon-empty-state>
    `
  }
}

declare global {
  interface HTMLElementTagNameMap {
    readonly [SearchError.tagName]: SearchError
  }
}
