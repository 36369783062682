import { html } from 'lit-element'
import type { InternalGeneralSettingLink } from '../settings-models'
import { nothing } from 'lit-html'
import generalSettingsStyles from './general-settings.styles.scss'
import { repeat } from 'lit-html/directives/repeat'
import { resolvePromise } from '../../../directives/resolved-promise'
import { isVisible, renderMenuItem } from '../settings-helpers'
import { generalSettingsLinks } from '../../../experiences/navigation-links'
import { t } from '../../../directives/translate/translate'
import { ShellElement } from '../../../common/shell-element'
import { getEventBus } from '../../../services/namespaces/event-bus'
import type { shellEvents } from '../../../services/shell-namespace'
import { ShellNamespace } from '../../../services/shell-namespace'

export class GoToGeneralSettings extends ShellElement {
  static readonly tagName = 'goto-general-settings'

  static get styles() {
    return generalSettingsStyles
  }

  private async renderLink(link: InternalGeneralSettingLink) {
    return (await isVisible(link)) ? renderMenuItem(link) : nothing
  }

  connectedCallback() {
    super.connectedCallback()
    this.subscribeToRoutingChanges()
  }

  private subscribeToRoutingChanges(): void {
    const { routeChanged } = getEventBus().subscribeTo<typeof ShellNamespace, typeof shellEvents>(ShellNamespace)
    routeChanged.on(this.rerenderGeneralSettings)
    this.unsubscribeFunctions.push(() => routeChanged.removeListener(this.rerenderGeneralSettings))
  }

  private readonly rerenderGeneralSettings = () => {
    this.requestUpdate()
  }

  render() {
    return generalSettingsLinks.length
      ? html`
          <chameleon-navigation-drawer-section>${t('General')}</chameleon-navigation-drawer-section>
          ${repeat(
            generalSettingsLinks,
            link => link.name,
            link => resolvePromise(this.renderLink(link)),
          )}
        `
      : nothing
  }
}

declare global {
  interface HTMLElementTagNameMap {
    readonly [GoToGeneralSettings.tagName]: GoToGeneralSettings
  }
}
