import type { ExtensionConfig } from '@goto/shell-common'
import { PrerequisiteRuleTypes } from '@goto/shell-common'

const engagementsExtensionConfig: ExtensionConfig = {
  id: '@dce/engagements-ui-extension',
  url: new URL('/experiences/engagements/@dce/engagements-ui-extension.mjs', location.origin),
  esModule: true,
  prerequisites: {
    [PrerequisiteRuleTypes.CONTACT_CENTER_FLAGS]: 'engagements',
  },
  dependencies: [
    '@dce/engagements-web-components-loader-extension',
    '@ucc/cc-config/extension'
  ],
  optionalDependencies: [
    '@goto/contacts-ui-extension'
  ],
  integrationConfig: {
    shouldLoadInIntegration: true,
  },
}

const onboardingExtensionConfig: ExtensionConfig = {
  id: '@dce/engagements-onboarding-ui-extension',
  url: new URL('/experiences/engagements/onboarding/@dce/engagements-onboarding-ui-extension.mjs', location.origin),
  esModule: true,
  prerequisites: {
    [PrerequisiteRuleTypes.CONTACT_CENTER_FLAGS]: 'engagements',
  },
  dependencies: [
    '@dce/engagements-web-components-loader-extension'
  ],
  integrationConfig: {
    shouldLoadInIntegration: true,
  },
}

const plgExtensionConfig: ExtensionConfig = {
  id: '@dce/engagements-plg-ui-extension',
  url: new URL('/experiences/engagements/plg/@dce/engagements-plg-ui-extension.mjs', location.origin),
  esModule: true,
  prerequisites: {
    [PrerequisiteRuleTypes.CONTACT_CENTER_FLAGS]: 'plg-motions.engagements && !engagements',
  },
  dependencies: [
    '@dce/engagements-web-components-loader-extension'
  ],
  integrationConfig: {
    shouldLoadInIntegration: true,
  },
}

// Voluntarily without prerequisites. WC can be loaded by many extensions
// and they must have access to the commands to load them.
// WCs are loaded on demand only.
const wcLoaderExtensionConfig: ExtensionConfig = {
  id: '@dce/engagements-web-components-loader-extension',
  url: new URL('/experiences/engagements/wc-loader/extension.mjs', location.origin),
  esModule: true,
  integrationConfig: {
    shouldLoadInIntegration: true,
  },
}

export default [engagementsExtensionConfig, onboardingExtensionConfig, plgExtensionConfig, wcLoaderExtensionConfig]
