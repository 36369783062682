import { html, property, query } from 'lit-element'
import { guard } from 'lit-html/directives/guard'
import { repeat } from 'lit-html/directives/repeat'
import { nothing } from 'lit-html'
import { ifDefined } from 'lit-html/directives/if-defined'

import { getMenuItems } from '../avatar-popover-menu-items/avatar-popover-menu-items'
import { renderNormalMenuItem } from '../menu-item/menu-item-renderer'
import type { MenuItem } from '../menu-item/menu-item-models'
import { MenuItemType } from '../menu-item/menu-item-models'
import avatarPopoverMenuStyles from './avatar-popover-menu.styles.scss'
import { getShellApiInstance, getShellAnalytics } from '../../common/shell-api-helpers'
import { ShellElement } from '../../common/shell-element'
import type { PresenceNamespace } from '../../services/namespaces/presence-namespace'
import { PRESENCE_NAMESPACE } from '../../services/namespaces/presence-namespace'
import { t } from '../../directives/translate'
import { getExternalInterface } from '../../services/external-interface/external-interface-adapter'
import { AvatarSize } from '../../services/avatar/models'
import { type GoToAvatarUpdateMenuItem } from '../update-app'

export interface AvatarAndName {
  readonly givenName: string
  readonly familyName: string
  readonly profileImageUrl: string
}

const renderAccountSwitcher = () =>
  getExternalInterface().isIntegration
    ? html`<goto-account-switcher></goto-account-switcher><chameleon-menu-separator></chameleon-menu-separator>`
    : nothing

// Normally this div should be with role="menuitem" tabindex="-1", but we don't need to announce it
// Since the goto-avatar-container tooltip, avatar and presence indicator will already have announced
const renderAvatarAndName = ({ familyName, givenName, profileImageUrl }: AvatarAndName) =>
  guard([familyName, givenName, profileImageUrl], () => {
    const externalUserKey = getShellApiInstance().user.key ?? ''
    const avatarSize = AvatarSize.X_LARGE
    const typeVariant = 'body-medium-semibold'
    const shouldShowPresence = true
    return html`
      <div class="avatar-and-name" aria-hidden="true">
        <goto-avatar
          .showPresence=${shouldShowPresence}
          .externalUserKey=${externalUserKey}
          .familyName=${familyName}
          .givenName=${givenName}
          .profileImageUrl=${profileImageUrl}
          .size=${avatarSize}
          variant="primary"
          .hidePresenceTooltip=${true}
          useChameleonAvatarV2
        ></goto-avatar>
        <div class="name-and-presence">
          <chameleon-typography class="user-name" variant=${typeVariant}
            >${givenName} ${familyName}</chameleon-typography
          >
          <goto-presence-selector></goto-presence-selector>
        </div>
      </div>
    `
  })

const renderUserNoteInput = (clickHandler: () => void): ReturnType<typeof html> | typeof nothing =>
  html` <goto-user-note-input @click=${clickHandler}></goto-user-note-input>`

const renderMenuItemTemplate = (item: MenuItem): ReturnType<typeof html> | typeof nothing => {
  const isVisible = item.isVisible?.() ?? true

  if (isVisible) {
    switch (item.type) {
      case MenuItemType.SEPARATOR:
        return html`<chameleon-menu-separator data-test=${ifDefined(item.dataTest)}></chameleon-menu-separator>`
      case MenuItemType.CUSTOM:
        return item.render()
      case MenuItemType.NORMAL:
        return renderNormalMenuItem(item)
    }
  }
  return nothing
}

export class GoToAvatarPopoverMenu extends ShellElement implements AvatarAndName {
  static readonly tagName = 'goto-avatar-popover-menu'
  @property({ type: String }) givenName = ''
  @property({ type: String }) familyName = ''
  @property({ type: String }) profileImageUrl = ''
  @query('#update-menu-item') private readonly updateMenuItem: GoToAvatarUpdateMenuItem | undefined

  static get styles() {
    return avatarPopoverMenuStyles
  }

  connectedCallback() {
    super.connectedCallback()
    this.addEventListener('removeUpdateMenuItem', this.handleRemoveUpdateMenuItem)
  }

  disconnectedCallback() {
    this.removeEventListener('removeUpdateMenuItem', this.handleRemoveUpdateMenuItem)
    super.disconnectedCallback()
  }

  private readonly handleRemoveUpdateMenuItem = () => {
    this.updateMenuItem?.remove()
  }

  render() {
    return html`
      <chameleon-menu label=${t('User profile menu')}>
        ${renderAccountSwitcher()}${renderAvatarAndName(this)}
        ${renderUserNoteInput(this.handleOpenEditUserNoteDialog.bind(this))}
        ${repeat(getMenuItems(), renderMenuItemTemplate)}
        <chameleon-menu-separator></chameleon-menu-separator>
        <chameleon-menu-section>
          <goto-version></goto-version>
        </chameleon-menu-section>
      </chameleon-menu>
    `
  }

  private handleOpenEditUserNoteDialog() {
    // Mixpanel; Open Custom Status modal
    getShellAnalytics().track('GoTo > Custom Status Modal', { action: 'Click', event: 'Open', from: 'Avatar menu' })

    getShellApiInstance()
      .namespaces.retrieve<PresenceNamespace>(PRESENCE_NAMESPACE)
      .commands.openPresenceNoteDialog.execute()
  }
}

declare global {
  interface HTMLElementTagNameMap {
    readonly [GoToAvatarPopoverMenu.tagName]: GoToAvatarPopoverMenu
  }
}
