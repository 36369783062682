import hotkeySectionRowStyles from './keyboard-shortcut-section-row.styles.scss'
import { html, LitElement, property, state } from 'lit-element'
import { repeat } from 'lit-html/directives/repeat'
import type { KeyboardShortcutErrorState, SaveKeyCombinationSnackbarStatus } from './keyboard-shortcut.models'
import { HotkeyAction } from './keyboard-shortcut.models'
import { SVG_FROM_CHANNEL_FILLED } from '@getgo/chameleon-icons'
import {
  KeyCombinationValidator,
  saveConfigurationToExternalService,
  getEmptyKeyCombination,
} from '../../services/keyboard-shortcut/keyboard-shortcut'
import { updateKeyBoardShortcutConfiguration } from '../../services/keyboard-shortcut/keyboard-shortcut-events'
import type { KeyboardShortcutConfig, KeyboardShortcutKeyCombination } from '@getgo/container-client'
import { asTranslationKey } from '../../common/translate-helpers/i18n-utils'
import { isContainer } from '../../services/container/helpers'
import { Plugins } from '@getgo/container-client'
import { t } from '../../directives/translate'
import { KeyboardShortcutUIStructureProvider } from './keyboard-shortcut-UI-structure-provider'
import { type SnackbarNotification } from '../../services/display-notification'
import { getShellApiInstance } from '../../common/shell-api-helpers'

export class GoToKeyboardShortcutSectionRow extends LitElement {
  static readonly tagName = 'goto-keyboard-shortcut-section-row'

  private readonly customKeyboardShortcutValues = KeyboardShortcutUIStructureProvider.getCustomValue()

  @property({ type: Object }) hotkeyAction: HotkeyAction = {
    globalKeyCombination: [],
    localKeyCombination: [],
    label: '',
    id: 'newItem',
    configuration: {
      isLocalEditable: true,
      isGlobalEditable: true,
    },
  }
  @property({ type: Object }) keyCombinationValidator: KeyCombinationValidator = new KeyCombinationValidator()

  @state() hasInputError = false

  @state() localKeyCombinationEdited: Array<KeyboardShortcutConfig> = []
  @state() globalKeyCombinationEdited: Array<KeyboardShortcutConfig> = []

  static get styles() {
    return hotkeySectionRowStyles
  }

  connectedCallback() {
    super.connectedCallback()
    this.localKeyCombinationEdited = [...this.hotkeyAction.localKeyCombination]

    this.globalKeyCombinationEdited = isContainer() ? [...this.hotkeyAction.globalKeyCombination] : []
  }

  private async updateAndSave(allCombinations: ReadonlyArray<KeyboardShortcutConfig>) {
    const result = await updateKeyBoardShortcutConfiguration(this.hotkeyAction.id, allCombinations)
    if (result) {
      const updatedConfig = saveConfigurationToExternalService(this.hotkeyAction.id, allCombinations)
      this.keyCombinationValidator.fillListOfUsedCombinationsFromConfig?.(updatedConfig)
      this.displaySaveMessage('success')
    } else {
      this.displaySaveMessage('error')
    }
    return result
  }
  
  private async onGlobalHotKeySwitchChange(e: CustomEvent) {
    if ((e.currentTarget as HTMLInputElement).checked) {
      // User just enabled global key combination for this shortcut. Initialize with an empty key combination.
      this.globalKeyCombinationEdited = [{ global: true, keyCombination: getEmptyKeyCombination() }]
    } else {
      // User just disabled the global key combination for this shortcut.
      this.globalKeyCombinationEdited = []
    }

      // Update and save.
      const allCombinations = [...this.localKeyCombinationEdited, ...this.globalKeyCombinationEdited]
      await this.updateAndSave(allCombinations)
  }

  private combinationsAreEqual(keyCombination1: KeyboardShortcutKeyCombination, keyCombination2: KeyboardShortcutKeyCombination): boolean {
    return this.keyCombinationValidator.combinationsAreEqual(keyCombination1, keyCombination2)
  }

  private onValidateKeyCombination(keyCombination: KeyboardShortcutKeyCombination): KeyboardShortcutErrorState {
    // Validate if the input is a duplicate
    if (this.keyCombinationValidator.isCombinationBeingUsed(keyCombination)) {
      this.hasInputError = true
      return {
        errorCodes: ['AlreadyUsedAnotherShortcut'],
        isInvalid: true,
      }
    }

    const validationResult =
      Plugins.KeyboardShortcuts.systemInterface.isValidKeyboardShortcutKeyCombination(keyCombination)

    this.hasInputError = !!validationResult.errorCodes.length
    return validationResult
  }

  private async onSaveKeyCombination(
    keyCombination: KeyboardShortcutKeyCombination,
    position: number,
    isLocal: boolean,
  ) {
    // Add the combinations we are editing to the array first.
    const allCombinations = isLocal ? [...this.localKeyCombinationEdited] : [...this.globalKeyCombinationEdited]

    // Make the specified modification.
    const { code, shiftKey, ctrlKey, altKey, metaKey } = keyCombination
    allCombinations[position] = {
      keyCombination: { code, shiftKey, ctrlKey, altKey, metaKey },
      global: !isLocal,
    }

    // Append the other combinations to the array (append global if we edited local, append local if we edited global).
    if (isLocal) {
      allCombinations.push(...this.globalKeyCombinationEdited)
    } else {
      allCombinations.push(...this.localKeyCombinationEdited)
    }

    // Update and save.
    const result = await this.updateAndSave(allCombinations)
    if (result) {
      this.localKeyCombinationEdited = allCombinations.filter(config => !config.global)
      this.globalKeyCombinationEdited = allCombinations.filter(config => config.global)
    }
    return result
  }

  private async onClearKeyCombination(position: number, isLocal: boolean) {
    // Add the combinations we are editing to the array first.
    const allCombinations = isLocal ? [...this.localKeyCombinationEdited] : [...this.globalKeyCombinationEdited]

    // Make the specified modification (clear the combination).
    allCombinations[position] = {
      keyCombination: getEmptyKeyCombination(),
      global: !isLocal,
    }

    // Append the other combinations to the array (append global if we edited local, append local if we edited global).
    if (isLocal) {
      allCombinations.push(...this.globalKeyCombinationEdited)
    } else {
      allCombinations.push(...this.localKeyCombinationEdited)
    }

    // Update and save.
    const result = await this.updateAndSave(allCombinations)
    if (result) {
      this.hasInputError = false
      this.localKeyCombinationEdited = allCombinations.filter(config => !config.global)
      this.globalKeyCombinationEdited = allCombinations.filter(config => config.global)
    }
    return result
  }

  private displaySaveMessage(status: SaveKeyCombinationSnackbarStatus) {
    const snackBarOptions: Record<SaveKeyCombinationSnackbarStatus, SnackbarNotification> = {
      success: {
        id: 'keyboard-shortcut-input-snackbar',
        title: 'Keyboard shortcut saved',
      },
      error: {
        id: 'keyboard-shortcut-input-snackbar',
        title: 'Error',
        message: 'Cannot save the configuration',
      },
    }
    getShellApiInstance().display.snackbar({
      ...snackBarOptions[status],
    })
  }

  render() {
    return html`<div class="hotkey-shortcut">
      <div class="hotkey-shortcut-local-section">
        <div class="hotkey-shortcut-local-label">
          <chameleon-typography
            variant="body-large"
            color=${this.hasInputError ? 'type-color-danger' : 'type-color-secondary'}
            >${this.hotkeyAction.label}</chameleon-typography
          >
        </div>
        <div class="hotkey-shortcut-keys">
          ${repeat(
            this.localKeyCombinationEdited,
            key => Object.values(key).join('_') + '_local',
            (keyboardShortcutConfig, index) => html`
              <goto-keyboard-shortcut-input
                .keyCombination=${keyboardShortcutConfig.keyCombination}
                .position=${index}
                .customValue=${this.customKeyboardShortcutValues[this.hotkeyAction.id]}
                .isEditable=${this.hotkeyAction.configuration.isLocalEditable}
                .isLocal=${keyboardShortcutConfig.global}
                .combinationsAreEqual=${(
                  keyCombination1: KeyboardShortcutKeyCombination,
                  keyCombination2: KeyboardShortcutKeyCombination,
                ) => this.combinationsAreEqual(keyCombination1, keyCombination2)}
                .validateKeyCombination=${(keyCombination: KeyboardShortcutKeyCombination) =>
                  this.onValidateKeyCombination(keyCombination)}
                .saveKeyCombination=${(keyCombination: KeyboardShortcutKeyCombination) =>
                  this.onSaveKeyCombination(keyCombination, index, true)}
                .clearKeyCombination=${() => this.onClearKeyCombination(index, true)}
              ></goto-keyboard-shortcut-input>
            `,
          )}
        </div>
        ${this.renderGlobalHotkeyEditSwitch()}
      </div>
      ${this.hotkeyAction.configuration.isGlobalDisabled ? null : this.renderGlobalHotkeySection()}
    </div>`
  }

  private getGlobalHotkeyEditSwitchLabel() {
    const prefix =
      this.globalKeyCombinationEdited.length > 0 ? t('Remove global shortcut for') : t('Add a global shortcut for')
    return `${prefix} ${this.hotkeyAction}`
  }

  private renderGlobalHotkeyEditSwitch() {
    if (isContainer() && !this.hotkeyAction.configuration.isGlobalDisabled) {
      return html` <div class="hotkey-shortcut-global-switch-key">
        <chameleon-tooltip-v2 position="bottom-center">
          <chameleon-switch
            slot="trigger"
            @change=${(e: CustomEvent) => this.onGlobalHotKeySwitchChange(e)}
            ?checked=${this.globalKeyCombinationEdited.length > 0}
            ?disabled=${!this.hotkeyAction.configuration.isGlobalEditable}
            label=${this.getGlobalHotkeyEditSwitchLabel()}
          ></chameleon-switch>
          <span slot="content"
            >${this.globalKeyCombinationEdited.length > 0
              ? t('Remove global shortcut')
              : t('Add a global shortcut')}</span
          >
        </chameleon-tooltip-v2>
      </div>`
    }

    return null
  }

  private renderGlobalHotkeySection() {
    return this.globalKeyCombinationEdited.length
      ? html`
          <div class="key-combine-global-section">
            <div class="key-combine-global-svg"><chameleon-svg>${SVG_FROM_CHANNEL_FILLED}</chameleon-svg></div>
            <div class="key-combine-global-box-section">
              <chameleon-typography
                class="key-combine-global-label-section"
                variant="body-medium"
                color="type-color-secondary"
                >${asTranslationKey('Global shortcut')}</chameleon-typography
              >
              <div class="hotkey-shortcut-keys">
                ${repeat(
                  this.globalKeyCombinationEdited,
                  key => Object.values(key).join('_') + '_global',
                  (keyboardShortcutConfig, index) =>
                    html`<goto-keyboard-shortcut-input
                      .keyCombination=${keyboardShortcutConfig.keyCombination}
                      .position=${index}
                      .isEditable=${this.hotkeyAction.configuration.isGlobalEditable}
                      .isLocal=${keyboardShortcutConfig.global}
                      .combinationsAreEqual=${(
                        keyCombination1: KeyboardShortcutKeyCombination,
                        keyCombination2: KeyboardShortcutKeyCombination,
                      ) => this.combinationsAreEqual(keyCombination1, keyCombination2)}
                      .validateKeyCombination=${(keyCombination: KeyboardShortcutKeyCombination) =>
                        this.onValidateKeyCombination(keyCombination)}
                      .saveKeyCombination=${(keyCombination: KeyboardShortcutKeyCombination) =>
                        this.onSaveKeyCombination(keyCombination, index, false)}
                      .clearKeyCombination=${() => this.onClearKeyCombination(index, false)}
                    ></goto-keyboard-shortcut-input>`,
                )}
              </div>
            </div>
            <div class="hotkey-shortcut-global-switch-key"></div>
          </div>
        `
      : null
  }
}

declare global {
  interface HTMLElementTagNameMap {
    readonly [GoToKeyboardShortcutSectionRow.tagName]: GoToKeyboardShortcutSectionRow
  }
}
