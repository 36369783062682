import { ShellElement } from '../../common/shell-element'
import { html, property } from 'lit-element'
import { t } from '../../directives/translate'
import { getDialogService } from '../../services/dialog/public-facade'

export class GotoAssistant extends ShellElement {
  static readonly tagName = 'goto-assistant'

  @property({ type: String }) searchString = ''

  close() {
    getDialogService().close()
  }

  render() {
    return html`
      <chameleon-dialog open closable size="large" @closed=${this.close} close-button-label=${t('Close dialog')}>
        <div class="goto-assistant">
          <iframe
            title="goto-assistant"
            src="https://chat.dante-ai.com/embed?kb_id=aeb24871-d2ff-42eb-a1dc-e081dd83f088&token=c17f6983-5f8c-4b38-a43f-a6b4b10d9e75&modeltype=gpt-4-turbo&logo=dHJ1ZQ==&mode=false"
            allow="clipboard-write; microphone"
            width="100%"
            height="500"
            frameborder="0"
          ></iframe>
        </div>
      </chameleon-dialog>
    `
  }
}

declare global {
  interface HTMLElementTagNameMap {
    readonly [GotoAssistant.tagName]: GotoAssistant
  }
}
