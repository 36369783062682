import { html, property } from 'lit-element'
import { nothing } from 'lit-html'
import { repeat } from 'lit-html/directives/repeat'
import { resolvePromise } from '../../../directives/resolved-promise'
import { t } from '../../../directives/translate'
import { isVisible, renderMenuItem } from '../settings-helpers'
import type { InternalSpecificSettingLink, InternalGeneralSettingLink } from '../settings-models'
import specificSettingsStyles from './specific-settings.styles.scss'
import { asTranslationKey } from '../../../common/translate-helpers/i18n-utils'
import { getEventBus } from '../../../services/namespaces/event-bus'
import type { shellEvents } from '../../../services/shell-namespace'
import { ShellNamespace } from '../../../services/shell-namespace'
import { ShellElement } from '../../../common/shell-element'
export class GoToSpecificSettings extends ShellElement {
  static readonly tagName = 'goto-specific-settings'
  @property({ type: Array })
  links: readonly InternalSpecificSettingLink[] = []

  static get styles() {
    return specificSettingsStyles
  }
  connectedCallback() {
    super.connectedCallback()
    this.processLinksAndSubscribe()
  }

  private subscribeToRoutingChanges(): void {
    const { routeChanged } = getEventBus().subscribeTo<typeof ShellNamespace, typeof shellEvents>(ShellNamespace)
    routeChanged.on(this.rerenderSpecificSettings)
    this.unsubscribeFunctions.push(() => routeChanged.removeListener(this.rerenderSpecificSettings))
  }

  private readonly rerenderSpecificSettings = () => {
    this.requestUpdate()
  }

  private buildMenuItemLink(link: InternalSpecificSettingLink) {
    const { settingsIcon: icon, settingsLinksInfo } = link
    const { name, href } = settingsLinksInfo[0]
    return {
      href,
      icon,
      name,
    } as InternalGeneralSettingLink
  }

  processLinksAndSubscribe() {
    this.links.forEach(link => {
      if (link.settingsLinksInfo.length === 1) {
        this.subscribeToRoutingChanges()
      }
    })
  }

  private async renderLink(link: InternalSpecificSettingLink) {
    // TODO: isVisible should be per accordion link and not the whole thing
    // currently it won't ever render an accordion
    // probably something like await all promises of the accordion
    // if we have at least a single entry we can render our component
    if (!(await isVisible(link))) {
      return nothing
    }

    return link.settingsLinksInfo.length === 1
      ? renderMenuItem(this.buildMenuItemLink(link))
      : html`<goto-specific-settings-accordion .link=${link}></goto-specific-settings-accordion>`
  }

  render() {
    const title = asTranslationKey('Capabilities')

    return this.links.length
      ? html`
          <chameleon-navigation-drawer-section>${t(title)}</chameleon-navigation-drawer-section>
          ${repeat(
            this.links,
            link => link.settingsIcon,
            link => resolvePromise(this.renderLink(link)),
          )}
        `
      : nothing
  }
}

declare global {
  interface HTMLElementTagNameMap {
    readonly [GoToSpecificSettings.tagName]: GoToSpecificSettings
  }
}
