import { html, state } from 'lit-element'
import integrationTopbarStyles from './integration-topbar.styles.scss'
import { t } from '../../directives/translate'
import { SVG_SETTINGS_OUTLINED } from '@getgo/chameleon-icons'
import { unsafeSVG } from 'lit-html/directives/unsafe-svg'
import { nothing } from 'lit-html'
import { isIntegrationMobile } from '../../core/integrations-helpers'
import { GotoBaseNavigationLinks } from '../navigation/base-navigation/base-navigation-links'
import { type UpdateInfo } from '@getgo/container-client'
import { UpdateNamespace, type UpdateEvents } from '../../services/update'
import { calculateUpdateAgeInDays, displayUpdateConfirmModalInIntegration } from '../../services/update/helpers'
import { getEventBus } from '../../services/namespaces/event-bus'
import type { Listener } from '../../services/namespaces'
import { sendMessageGetUpdateStatus } from '../../services/external-interface/external-interface-companion-update-helper'

export class GoToIntegrationTopbar extends GotoBaseNavigationLinks {
  static readonly tagName = 'goto-integration-topbar'

  @state() private updateAge: number | undefined

  static get styles() {
    return integrationTopbarStyles
  }

  connectedCallback(): void {
    super.connectedCallback()
    this.listenToUpdateAvailableEvent()
  }

  private resetUpdateAge() {
    this.updateAge = undefined
  }

  private listenToUpdateAvailableEvent() {
    const { updateAvailable } = getEventBus().subscribeTo<typeof UpdateNamespace, typeof UpdateEvents>(UpdateNamespace)
    const { resetUpdateStatus } = getEventBus().subscribeTo<typeof UpdateNamespace, typeof UpdateEvents>(
      UpdateNamespace,
    )

    const calculateUpdateAge: Listener<UpdateInfo> = (updateInfo: UpdateInfo) => {
      this.updateAge = calculateUpdateAgeInDays(updateInfo)
    }

    updateAvailable.on(calculateUpdateAge)
    resetUpdateStatus.on(this.resetUpdateAge.bind(this))

    this.unsubscribeFunctions.push(() => {
      updateAvailable.removeListener(calculateUpdateAge)
      resetUpdateStatus.removeListener(this.resetUpdateAge)
    })

    //We are sending the following message to get the update status from the companion
    //This is to make sure that the update chip is displayed in the case when the companion has already started before the integration loads
    //This message is originally sent when the external interface is initialized but that is too early in the life cycle for this component
    //to listen to the updateAvailable event
    sendMessageGetUpdateStatus()
  }

  private handleUpdateChipClick() {
    displayUpdateConfirmModalInIntegration()
  }

  private renderUpdateButton(updateAge?: number) {
    return updateAge != undefined && !isIntegrationMobile()
      ? html`<li>
          <goto-update-chip .updateAge=${updateAge} @click=${this.handleUpdateChipClick}></goto-update-chip>
        </li>`
      : nothing
  }

  render() {
    return html`
      <div class="container">
        ${this.renderHorizontalNavRail()}
        <nav aria-label=${t('GoTo topbar')}>
          <ul>
            ${this.renderGlobalSearch()}${this.renderCallCardListItem()}${this.renderUpdateButton(
              this.updateAge,
            )}${this.renderUserProfileMenu()}
          </ul>
        </nav>
      </div>
    `
  }

  async linksUpdated() {
    // hook after links are updated
  }

  private renderHorizontalNavRail() {
    return html` <goto-nav-bar .links=${this.links}></goto-nav-bar>`
  }

  private renderGlobalSearch() {
    return !isIntegrationMobile()
      ? html`<li><goto-global-search class="dynamic-width"></goto-global-search></li>`
      : nothing
  }

  private renderCallCardListItem() {
    return html`
      <li>
        <shell-topbar-call-card-container></shell-topbar-call-card-container>
      </li>
    `
  }

  private renderUserProfileMenu() {
    return html`
      <li>
        <chameleon-icon-button size="xlarge" id="user-menu-trigger"
          ><chameleon-svg> ${unsafeSVG(SVG_SETTINGS_OUTLINED)}</chameleon-svg></chameleon-icon-button
        >
      </li>
      <chameleon-popover-v2 trigger-id="user-menu-trigger" menu position="bottom-end" label=${t('User profile menu')}
        ><goto-integration-user-menu></goto-integration-user-menu
      ></chameleon-popover-v2>
    `
  }
}

declare global {
  interface HTMLElementTagNameMap {
    readonly [GoToIntegrationTopbar.tagName]: GoToIntegrationTopbar
  }
}
