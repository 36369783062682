import { property, html } from 'lit-element'
/*
 * This import is different because it will be processed by sass-to-string instead of lit-scss-loader
 * Because Lit doesn't where to put the styles we have to inline them
 * https://github.com/lit/lit-element/issues/898
 */
import settingsStyles from './settings.scoped.scss'
import { t } from '../../directives/translate'
import type { InternalSpecificSettingLink } from './settings-models'
import { CALENDAR_SETTINGS_ROUTE, SHELL_SETTINGS_ROUTE } from '../../common/routes'
import { getLocationPathname } from '../../common/dom-helpers'
import { ShellElement } from '../../common/shell-element'
import { navigateToUrl, waitForNextEventCycle } from '../../common/helpers'
import { getEventBus } from '../../services/namespaces/event-bus'
import type { shellEvents } from '../../services/shell-namespace'
import { ShellNamespace } from '../../services/shell-namespace'
export interface GoToSettingsProps {
  readonly settingLinks: readonly InternalSpecificSettingLink[]
}

export class GoToSettings extends ShellElement {
  static readonly tagName = 'goto-settings'
  @property({ type: Array })
  settingLinks: readonly InternalSpecificSettingLink[] = []
  private readonly defaultUrl = CALENDAR_SETTINGS_ROUTE

  createRenderRoot() {
    return this
  }

  redirectToDefaultSettings = () => {
    const pathIsShellSettingsRoute = getLocationPathname() === SHELL_SETTINGS_ROUTE
    if (pathIsShellSettingsRoute) {
      waitForNextEventCycle().then(() => navigateToUrl(this.defaultUrl))
    }
  }

  connectedCallback() {
    super.connectedCallback()
    this.subscribeToRouteChanged()
  }

  private subscribeToRouteChanged() {
    const { routeChanged } = getEventBus().subscribeTo<typeof ShellNamespace, typeof shellEvents>(ShellNamespace)
    routeChanged.on(this.redirectToDefaultSettings)
    this.unsubscribeFunctions.push(() => routeChanged.removeListener(this.redirectToDefaultSettings))
  }

  render() {
    return html`
      <style scoped>
        ${settingsStyles}
      </style>
      <chameleon-navigation-drawer class="settings-sidebar">
        <chameleon-navigation-drawer-header>${t('Settings')}</chameleon-navigation-drawer-header>
        <goto-general-settings></goto-general-settings>
        <goto-specific-settings .links=${this.settingLinks} role=${'menuitem' as any}></goto-specific-settings>
      </chameleon-navigation-drawer>
      <div class="settings-content">
        <!-- This is the hook for sub settings page -->
        <div id="goto--settings-content"></div>
      </div>
    `
  }
}

declare global {
  interface HTMLElementTagNameMap {
    readonly [GoToSettings.tagName]: GoToSettings
  }
}
