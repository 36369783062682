import { DD_MM_YYYY_DATE_FORMAT } from './models'
import { formatDateISO, dateIsValid, dateIsTodayOrInFuture } from './../../common/helpers/date'
import { getTranslation } from './../../services/i18n/i18nUtils'
import { LitElement, state, property } from 'lit-element'
import { html } from 'lit-html'
import { isEmptyOrNullOrUndefined } from '../../common'
import datePickerStyles from './date-picker.styles.scss'
import { ifDefined } from 'lit-html/directives/if-defined'

export class GoToDatePicker extends LitElement {
  static readonly tagName = 'goto-date-picker'

  @property({ type: String }) label = ''
  @property({ type: Boolean }) requireFutureDate = false
  @property({ type: String }) placeholder = ''
  @property({ type: Boolean }) disabled = false
  @property() date = ''
  @state() dateError = false

  static get styles() {
    return datePickerStyles
  }

  private dateHasChanged(e: { target: HTMLInputElement }) {
    this.date = e.target.value
    this.removeDateErrorIfValid()
  }

  private removeDateErrorIfValid() {
    const isValid = dateIsValid(this.date) && !this.hasFutureDateError()
    if (isValid || isEmptyOrNullOrUndefined(this.date)) {
      this.dateError = false
    }
  }

  private setDateErrorIfInvalid() {
    const hasError = !dateIsValid(this.date) || this.hasFutureDateError()
    if (!isEmptyOrNullOrUndefined(this.date) && hasError) {
      this.dateError = true
    }
  }
  private hasFutureDateError() {
    return (
      dateIsValid(this.date) && this.requireFutureDate && !dateIsTodayOrInFuture(new Date(formatDateISO(this.date)))
    )
  }

  private formatDate(date: string) {
    let formattedDate = date
    if (formattedDate.length === 2 || formattedDate.length === 5) {
      formattedDate = `${formattedDate}/`
    }
    return formattedDate
  }

  private getErrorText() {
    if (!dateIsValid(this.date)) {
      return getTranslation('A date in the DD/MM/YYYY format is required')
    } else if (this.hasFutureDateError()) {
      return getTranslation('A future date is required')
    }
  }

  private getHelperText() {
    return this.dateError ? this.getErrorText() : getTranslation(DD_MM_YYYY_DATE_FORMAT)
  }

  render() {
    return html`
      <chameleon-text-field
        class="date-picker"
        value=${this.formatDate(this.date) ?? ''}
        current-value=${this.formatDate(this.date)}
        aria-label=${ifDefined(this.label)}
        type="text"
        name="date"
        placeholder=${ifDefined(this.placeholder)}
        @input=${this.dateHasChanged}
        @blur=${this.setDateErrorIfInvalid}
        ?error=${!!this.dateError}
        ?disabled=${this.disabled}
        >${this.label}<span class="helper-text" slot="helpertext">${this.getHelperText()}</span></chameleon-text-field
      >
    `
  }
}

declare global {
  interface HTMLElementTagNameMap {
    readonly [GoToDatePicker.tagName]: GoToDatePicker
  }
}
