import { switchAccount } from './../../services/account/events'
import { getShellApiInstance } from './../../common/shell-api-helpers'
import { getAccountsWithPbxFromPrincipalApi } from './../../services/context/index'
import accountSwitcherStyles from './account-switcher.styles.scss'
import { repeat } from 'lit-html/directives/repeat'
import { html, LitElement, property, state } from 'lit-element'
import { nothing } from 'lit-html'
import { ifDefined } from 'lit-html/directives/if-defined'
import type { ChangeEvent } from 'react'
import type { User } from '../../services/context/models'
import { isContainer } from '../../services/container/helpers'
import { getPbxIdFromJiveResourceName, getPbxUserIdFromJiveResourceName } from './account-switcher-helper'
import { getExternalInterface } from '../../services/external-interface/external-interface-adapter'

export class GoToAccountSwitcher extends LitElement {
  static readonly tagName = 'goto-account-switcher'

  @state() private externalUserKey = ''
  @state() private accounts: readonly User[] = []
  @state() private accountPbxId = ''
  @property({ type: String, attribute: 'selected', reflect: true }) selectedAccountKey: string | undefined

  static get styles() {
    return accountSwitcherStyles
  }

  async firstUpdated() {
    this.externalUserKey = getShellApiInstance()?.user?.key ?? ''
    const userEmail = getShellApiInstance().user.emails[0]?.value ?? null
    if (userEmail) {
      this.accounts = await getAccountsWithPbxFromPrincipalApi(userEmail)
    }
    this.setSelectedAccountKey()
  }

  private setSelectedAccountKey() {
    const contextAccountKey = getShellApiInstance().context.account?.key
    const defaultAccount = this.accounts[0]
    const found = this.accounts.some(account => account.accountKey === contextAccountKey)

    this.selectedAccountKey = found ? contextAccountKey : defaultAccount?.accountKey
  }

  private getSelectedAccountName() {
    return this.accounts.find(account => account.accountKey === this.selectedAccountKey)?.pbxName ?? ''
  }

  private renderOption(account: User) {
    return html` <chameleon-option value=${ifDefined(account.accountKey)}>${account.pbxName}</chameleon-option>`
  }

  private handleAccountChange(event: ChangeEvent<HTMLSelectElement>) {
    const target = event.target
    const targetAccountKey = target.value
    let accountPbxUserId = ''

    if (targetAccountKey) {
      getShellApiInstance().context.account?.updateAccountKey(targetAccountKey)

      if (this.accounts.length > 0) {
        const account = this.accounts.find(account => account.accountKey === targetAccountKey)
        this.accountPbxId = getPbxIdFromJiveResourceName(account?.jrn)
        accountPbxUserId = getPbxUserIdFromJiveResourceName(account?.jrn)
        const shellApiInstance = getShellApiInstance()
        shellApiInstance.context.pbx?.updateId(this.accountPbxId)
        shellApiInstance.context.pbx?.updateUserId(accountPbxUserId)
      } else {
        this.accountPbxId = ''
      }

      this.selectedAccountKey = targetAccountKey

      const switchAccountPayload = {
        accountKey: targetAccountKey,
        accountName: this.getSelectedAccountName(),
        pbxId: this.accountPbxId,
        pbxUserId: accountPbxUserId,
        externalUserKey: this.externalUserKey,
      }

      switchAccount(switchAccountPayload)
    }
  }

  render() {
    return this.accounts.length > 1
      ? html` <div class="account-switcher">
          ${isContainer() || getExternalInterface().isIntegration
            ? nothing
            : html`<div class="vertical-divider"></div>`}
          <chameleon-select
            ?fit-content=${!getExternalInterface().isIntegration}
            ?fullwidth=${getExternalInterface().isIntegration}
            no-border
            selected-value=${ifDefined(this.selectedAccountKey)}
            @change=${this.handleAccountChange}
          >
            ${repeat(this.accounts, this.renderOption)}
          </chameleon-select>
        </div>`
      : nothing
  }
}

declare global {
  interface HTMLElementTagNameMap {
    readonly [GoToAccountSwitcher.tagName]: GoToAccountSwitcher
  }
}
