import { html, state } from 'lit-element'

import { getExternalInterface } from '../../services/external-interface'
import { DesktopCompanionConnectionStatus, type DesktopCompanionStatusChangedEvent } from '@getgo/container-client'
import { ShellElement } from '../../common/shell-element'

export class ShellLandingController extends ShellElement {
  static readonly tagName = 'goto-shell-landing-controller'
  @state() private companionIsConnected = false

  connectedCallback() {
    super.connectedCallback()
    this.companionIsConnected = getExternalInterface().available

    const handleConnectionChanged = (event: DesktopCompanionStatusChangedEvent) => {
      this.companionIsConnected = event.connectionStatus === DesktopCompanionConnectionStatus.connected
    }

    getExternalInterface().addConnectionCallback(handleConnectionChanged)

    this.unsubscribeFunctions.push(() => {
      getExternalInterface().removeConnectionCallback(handleConnectionChanged)
    })
  }

  render() {
    if (getExternalInterface().supportsCompanion) {
      if (this.companionIsConnected) {
        return html`<goto-integration-connected-landing></goto-integration-connected-landing>`
      }
      return html`<goto-integration-disconnected-page></goto-integration-disconnected-page>`
    }

    return html`<goto-shell-landing></goto-shell-landing>`
  }
}

declare global {
  interface HTMLElementTagNameMap {
    readonly [ShellLandingController.tagName]: ShellLandingController
  }
}
