import {
  type DesktopCompanionMessage,
  Plugins,
  type DesktopCompanionStatusChangedEvent,
  type DesktopCompanionMessageEvent,
  DesktopCompanionConnectionStatus,
} from '@getgo/container-client'
import {
  type ExternalInterfaceConnectionCallback,
  type ExternalInterface,
  type ExternalInterfaceCallback,
} from './external-interface'
import { getWindow } from '../../common/dom-helpers'
import { MS_TEAMS_INTEGRATION_ID, getIntegrationMetaData } from '../../core/integrations-helpers'
import { getExternalInterfacePorts } from '../../core/environment'
import { type ExternalInterfaceMessage } from './messages/common'
import { isContainer } from '../container/helpers'
import { getShellLogger } from '../../common/logger'
import { isMobileUser } from '../../common/user-agent-helpers'

/**
 * @internal
 * ExternalInterface adapter.
 */
export class ExternalInterfaceAdapter implements ExternalInterface {
  private readonly handlers: Map<(event: DesktopCompanionMessageEvent) => void, ExternalInterfaceCallback<any>> =
    new Map()
  private readonly callerId = `external-interface-${Math.random()}`
  private pendingMessages: ExternalInterfaceMessage[] = []

  get available() {
    return Plugins.DesktopCompanion.connectionStatus === DesktopCompanionConnectionStatus.connected
  }

  get isCompanion() {
    return getIntegrationMetaData()?.id === MS_TEAMS_INTEGRATION_ID && isContainer()
  }

  get isIntegration() {
    return getIntegrationMetaData()?.id === MS_TEAMS_INTEGRATION_ID && !isContainer()
  }

  get supportsCompanion() {
    return this.isIntegration && !isMobileUser()
  }

  constructor() {
    this.addConnectionCallback(this.handleConnectionStatusChanged)
  }

  connect(): void {
    Plugins.DesktopCompanion.startConnecting({ ports: getExternalInterfacePorts() })
  }

  disconnect(): void {
    Plugins.DesktopCompanion.disconnect()
  }

  send<M extends ExternalInterfaceMessage>(msg: M): void {
    if (this.available) {
      try {
        Plugins.DesktopCompanion.send({
          ...msg,
          payload: { ...msg.payload, callerId: this.callerId },
        } as DesktopCompanionMessage)
      } catch (e) {
        getShellLogger().error(
          `Cannot send a message with the DesktopCompanion in external-interface-adapter, ignoring message of type: ${msg.type}`,
        )
      }
    } else {
      this.pendingMessages.push(msg)
    }
  }

  addCallback<EIM extends keyof ExternalInterfaceMessageMap>(
    type: EIM,
    callback: ExternalInterfaceCallback<ExternalInterfaceMessageMap[EIM]['payload']>,
  ): void
  addCallback<EIM extends ExternalInterfaceMessage>(
    type: EIM['type'],
    callback: ExternalInterfaceCallback<EIM['payload']>,
  ): void {
    const handler = (event: DesktopCompanionMessageEvent) => {
      if ('callerId' in event.message.payload && event.message.payload.callerId !== this.callerId) {
        callback(event.message.payload)
      }
    }
    this.handlers.set(callback, handler)
    Plugins.DesktopCompanion.addMessageEventListener(type as never, handler)
  }

  removeCallback<EIM extends keyof ExternalInterfaceMessageMap>(
    type: EIM,
    callback: ExternalInterfaceCallback<ExternalInterfaceMessageMap[EIM]['payload']>,
  ): void
  removeCallback<EIM extends ExternalInterfaceMessage>(
    type: EIM['type'],
    callback: ExternalInterfaceCallback<EIM['payload']>,
  ): void {
    const handler = this.handlers.get(callback)
    if (handler) {
      Plugins.DesktopCompanion.removeMessageEventListener(type as never, handler)
    }
  }

  addConnectionCallback(callback: ExternalInterfaceConnectionCallback): void {
    Plugins.DesktopCompanion.addEventListener('connectionStatusChanged', callback)
  }

  removeConnectionCallback(callback: ExternalInterfaceConnectionCallback): void {
    Plugins.DesktopCompanion.removeEventListener('connectionStatusChanged', callback)
  }

  private readonly handleConnectionStatusChanged = (e: DesktopCompanionStatusChangedEvent) => {
    if (e.connectionStatus === DesktopCompanionConnectionStatus.connected) {
      this.pendingMessages.forEach(msg => {
        this.send(msg)
      })

      this.pendingMessages = []
    }
  }
}

/**
 * Gets the ExternalInterface.
 * @returns the ExternalInterface
 */
export const getExternalInterface = (): ExternalInterface => getWindow().shellExternalInterface as ExternalInterface
