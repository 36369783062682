import { html, LitElement, query } from 'lit-element'
import { unsafeSVG } from 'lit-html/directives/unsafe-svg'
import { SVG_CLOSE_OUTLINED } from '@getgo/chameleon-icons'
import { getShellApiInstance } from '../../common/shell-api-helpers'
import { t } from '../../directives/translate'

export class GoToInspectorPanel extends LitElement {
  static readonly tagName = 'goto-inspector-panel'
  @query('.panel-content-container') panelContentContainer!: Element

  protected createRenderRoot(): Element | ShadowRoot {
    return this
  }

  open(content: HTMLElement) {
    this.panelContentContainer.innerHTML = ''
    this.panelContentContainer.appendChild(content)
    this.classList.add('open')
  }

  close() {
    this.panelContentContainer.innerHTML = ''
    this.classList.remove('open')
  }

  isOpen() {
    return this.classList.contains('open')
  }

  private handleClosePanelClick() {
    getShellApiInstance().inspectorPanel.close()
  }

  render() {
    return html`<div class="panel-container">
      <div class="close-panel-container">
        <chameleon-icon-button
          label=${t('Hide panel')}
          data-test="inspector-panel-close"
          size="small"
          variant="primary"
          @click=${this.handleClosePanelClick}
        >
          <chameleon-svg>${unsafeSVG(SVG_CLOSE_OUTLINED)}</chameleon-svg></chameleon-icon-button
        >
      </div>
      <div class="panel-content-container"></div>
    </div> `
  }
}

declare global {
  interface HTMLElementTagNameMap {
    readonly [GoToInspectorPanel.tagName]: GoToInspectorPanel
  }
}
