import { ShellElement } from './../../../common/shell-element'
import type { GlobalSearchActionUI } from './../global-search-models'
import { GlobalSearchResultUI } from './../global-search-models'
import { html, property } from 'lit-element'
import { nothing } from 'lit-html'
import { AvatarSize } from '../../../services/avatar/models'
import searchResultPreviewItem from './search-result-preview-item.styles.scss'
import { unsafeSVG } from 'lit-html/directives/unsafe-svg'
import { addToSearchHistory, getKeywordsFromSearchString } from '../helpers'
import { type GlobalSearchResultItem } from '@goto/shell-common'

export class SearchResultPreviewItem extends ShellElement {
  static readonly tagName = 'goto-search-result-preview-item'

  @property({ type: String }) searchString = ''
  @property({ type: Object }) searchResult: GlobalSearchResultUI = {
    title: '',
    preview: '',
    userKey: '',
    icon: '',
    actions: [],
    originalResult: {} as GlobalSearchResultItem,
    defaultAction: {} as GlobalSearchActionUI,
  }

  private shouldShowPresence() {
    return true
  }

  static get styles() {
    return searchResultPreviewItem
  }

  private handlePreviewItemActionClick(e: Event) {
    e.stopPropagation()
    this.dispatchEvent(new Event('preview-item-action-click'))
    addToSearchHistory({ query: this.searchResult.title })
  }

  private renderPreviewActions() {
    return html` ${this.searchResult.actions.length
      ? html`<div class="search-result-preview-actions">
          <goto-global-search-actions
            @click=${this.handlePreviewItemActionClick}
            .actions=${this.searchResult.actions}
            .originalResult=${this.searchResult.originalResult}
            searchString=${this.searchString}
          ></goto-global-search-actions>
        </div>`
      : nothing}`
  }

  private renderIconOrAvatar() {
    return html`${this.searchResult.userKey
      ? html`
          <goto-avatar
            .showPresence=${this.shouldShowPresence()}
            .externalUserKey=${this.searchResult.userKey}
            .familyName=${this.searchResult.familyName}
            .givenName=${this.searchResult.givenName}
            .size=${AvatarSize.LARGE}
            variant="primary"
            useChameleonAvatarV2
            label=${`${this.searchResult.givenName} ${this.searchResult.familyName}`}
          ></goto-avatar>
        `
      : html`
          <chameleon-avatar-v2 .size=${AvatarSize.LARGE} class="round-icon" aria-hidden="true" label="">
            <chameleon-svg>${unsafeSVG(this.searchResult.icon)}</chameleon-svg>
          </chameleon-avatar-v2>
        `}`
  }
  //TODO: highlight searchString matches in the title https://jira.ops.expertcity.com/browse/SCORE-2268
  private renderTitle() {
    return html` <div class="search-result-title">
      <chameleon-typography line-clamp="1" variant="body-small-strong">
        <goto-text-highlight ignoreCase ignoreAccents phoneNumbers .value=${this.searchResult.title} .keywords=${this.getKeywords()} .></goto-text-highlight>
      </chameleon-typography>
    </div>`
  }

  private getKeywords(): string[] {
    return getKeywordsFromSearchString(this.searchString)
  }

  //TODO: highlight searchString matches in the preview https://jira.ops.expertcity.com/browse/SCORE-2268
  private renderPreview() {
    return html` <div class="search-result-preview">
      <chameleon-typography
        line-clamp="1"
        class="search-result-preview"
        variant="caption-medium"
        color="type-color-secondary"
        ><goto-text-highlight ignoreCase ignoreAccents phoneNumbers .value=${this.searchResult.preview} .keywords=${this.getKeywords()} .></goto-text-highlight>
      </chameleon-typography>
    </div>`
  }

  render() {
    return html`<div role="tab" tabindex="-1" class="search-result-preview-item">
      <div class="search-result-preview-info">
        ${this.renderIconOrAvatar()}
        <div class="title-and-preview">${this.renderTitle()} ${this.renderPreview()}</div>
      </div>
      ${this.renderPreviewActions()}
    </div>`
  }
}

declare global {
  interface HTMLElementTagNameMap {
    readonly [SearchResultPreviewItem.tagName]: SearchResultPreviewItem
  }
}
