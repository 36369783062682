import type { ExtensionConfig } from '@goto/shell-common';

const contactCenterAgentBaseUrl = '/experiences/cc-agent';

export const extensionConfig: ExtensionConfig = {
  id: '@ucc/cc-agent/extension',
  url: new URL(`${contactCenterAgentBaseUrl}/extension.js`, location.origin),
  prerequisites: {
    ccFlags: 'cc-agent.portal',
  },
  integrationConfig: {
    shouldLoadInIntegration: true,
    shouldLoadInCompanion: true,
  },
}

export default extensionConfig
