import type { EOMInterfaceIdentifier } from '../eom'

/**
 * Extension that want to get options from the application could
 * extend this interface.  This is used when registering extensions (in the app) and
 * when the extension is initialized.
 *
 * @example In app
 * ``` ts
 *     const config = { url: new Url('myUrl'), myOption: true }
 *     Shell.addExtensions([config])
 * ```
 *
 *  @example In extension
 *   ```ts
 *   const messagingExtension = {
 *      /.../
 *      initialize: (config) => {
 *        if (config.myOption) {
 *          // do something here based on option passed by application
 *        }
 *      }
 *      /.../
 *  ```
 *   }
 *
 */
export interface ExtensionConfig {
  /**
   * Extension Id
   */
  readonly id: string
  /**
   * URL where the extension's script and css are stored
   */
  readonly url: URL

  /**
   * optional list of unguarded routes that does not require authentication
   */
  readonly unguardedRoutes?: readonly string[]

  /**
   * Whether the extension is served as an ES Module
   */
  readonly esModule?: boolean

  /**
   * list of route patterns that will load only that extension and it's dependencies
   */
  readonly standaloneRoutes?: readonly string[]

  /**
   * List of extension identifiers that are necessary to load prior this extension
   */
  readonly dependencies?: readonly string[]

  /**
   * List of extension identifiers that are optional to load prior to this extension
   */
  readonly optionalDependencies?: readonly string[]

  /**
   * List of conditions that need to pass before an extension is loaded
   */
  readonly prerequisites?: Prerequisites

  /**
   * List of all interfaces identifiers supported by the extension
   */
  readonly supportedInterfaces?: readonly InterfaceIdentifier[]

  /**
   * Optional config for extensions running within integrations
   */
  readonly integrationConfig?: {
    /**
     * Indicates whether this extension should be loaded when running on mobile
     * If not specified, the default is false
     */
    readonly shouldLoadOnMobile?: boolean
    /**
     * Indicates whether this extension should be loaded when running in an integration
     * If not specified, the default is false
     */
    readonly shouldLoadInIntegration?: boolean
    /**
     * Indicates whether this extension should be loaded when running in the companion app
     * If not specified, the default is false
     */
    readonly shouldLoadInCompanion?: boolean
    /**
     * Optional list of events that should be forwarded to the companion app
     * If the namespace is given, but no events are specified, all events are forwarded.
     * If namespace and events are specified, only the listed events are forwarded.
     * To have no events forwarded, do not provide a namespace to this config.*/
    readonly eventsToForwardToCompanion?: EventsNamespaceDefinition[]
    /**
     * Optional list of commands that should be executed by the companion app
     * If the namespace is given, but no commands are specified, all commands are forwarded.
     * If namespace and commands are specified, only the listed commands are forwarded.
     * To have no commands forwarded, do not provide a namespace to this config.*/
    readonly commandsToForwardToCompanion?: CommandsNamespaceDefinition[]
  }
}

/**
 * Events namespace definition
 */
export interface EventsNamespaceDefinition {
  readonly namespace: string
  readonly eventNames?: string[]
}

/**
 * Command namespace definition
 */
export interface CommandsNamespaceDefinition {
  readonly namespace: string
  readonly commandNames?: readonly string[]
}

export type InterfaceIdentifier = EOMInterfaceIdentifier | string

export interface Prerequisites {
  readonly [PrerequisiteRuleTypes.ENTITLEMENTS]?: string
  readonly [PrerequisiteRuleTypes.ROLES]?: string
  readonly [PrerequisiteRuleTypes.PBX_FLAGS]?: string
  readonly [PrerequisiteRuleTypes.CONTACT_CENTER_FLAGS]?: string
  readonly [PrerequisiteRuleTypes.SHELL_FEATURE_FLAGS]?: string
  readonly [PrerequisiteRuleTypes.ACCOUNT_SETTINGS]?: string
}

export enum PrerequisiteRuleTypes {
  ENTITLEMENTS = 'entitlements',
  ROLES = 'roles',
  PBX_FLAGS = 'pbxFlags',
  CONTACT_CENTER_FLAGS = 'ccFlags',
  SHELL_FEATURE_FLAGS = 'shellFlags',
  ACCOUNT_SETTINGS = 'accountSettings',
}
