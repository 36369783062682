import { html, LitElement, property } from 'lit-element'
import styles from './integration-redirect-proxy.styles.scss'
import { t } from 'i18next'
import { getMobileOSDownloadInfo } from '../../common/user-agent-helpers'
import { navigateToExternal } from '../../common/dom-helpers'
import { navigateToUrl } from '../../common/helpers'
import { getShellLogger } from '../../common/logger'

export interface IntegrationRedirectProxyData {
  url: string
  description: string
}

const PROTOCOL_ALLOW_LIST = ['com.logmein.gotoconnect.android://', 'com.logmein.gotoconnect://', 'gotoforteams://']

export class IntegrationRedirectProxy extends LitElement {
  static readonly tagName = 'goto-integration-redirect-proxy'
  @property({ type: Object, attribute: 'data' }) data = {} as IntegrationRedirectProxyData

  static get styles() {
    return styles
  }

  private readonly proxyRedirect = () => {
    const url = this.data?.url
    if (this.isRedirectUrlValid(url)) {
      navigateToExternal(url)
    } else {
      getShellLogger().error('Invalid redirect URL: ', url)
    }
  }

  private readonly isRedirectUrlValid = (url: string) =>
    PROTOCOL_ALLOW_LIST.some(allowedUrl => url.startsWith(allowedUrl))

  firstUpdated() {
    this.proxyRedirect()
  }

  handleDownloadMobileAppClick() {
    const { downloadUrl } = getMobileOSDownloadInfo()
    navigateToUrl(downloadUrl)
  }

  render() {
    return html` <div class="integration-redirect-proxy-container">
      <chameleon-goto-logo></chameleon-goto-logo>
      <chameleon-typography variant="heading-large" class="container-title">
        ${this.data.description}
      </chameleon-typography>
      <!-- TODO: Make these values dynamic (https://jira.ops.expertcity.com/browse/SCORE-3044) -->
      <chameleon-typography variant="body-medium" class="container-description">
        ${t('If prompted, allow your browser to open the GoTo app.')}
      </chameleon-typography>
      <chameleon-typography variant="body-medium" class="container-description"
        >${t('Nothing happening? Try this button:')}</chameleon-typography
      >
      <chameleon-button @click=${this.proxyRedirect} variant="primary" size="large" class="user-action-container"
        >${t('Open the app')}</chameleon-button
      >
      <chameleon-typography variant="body-medium" class="container-description">
        ${t(`Don’t have the app? `)}
        <chameleon-link variant="body-medium" appearance="primary" @click=${this.handleDownloadMobileAppClick}
          >${t('Get it now')}</chameleon-link
        >
      </chameleon-typography>
      <!-- end of values to be made dynamic -->
    </div>`
  }
}

declare global {
  interface HTMLElementTagNameMap {
    readonly [IntegrationRedirectProxy.tagName]: IntegrationRedirectProxy
  }
}
