import type { Presence as ChameleonPresence } from '@getgo/chameleon-core'
import type { ExternalUserKey, ISO8601Timestamp } from '../../core/models'
import type { EventEmitter } from '../namespaces'

export interface PresenceEventsEmitMap {
  /** {@link PresenceUpdated} */
  readonly presenceUpdate: EventEmitter<PresenceUpdated>
  /** {@link UserPresenceUpdated} */
  readonly userPresenceUpdate: EventEmitter<UserPresenceUpdated>
}

export const NotificationChannelMessageTypePresence = 'presence'

/**
 * The functional state of the observed user's presence status.
 */
export enum Appearance {
  UNAVAILABLE = 'UNAVAILABLE',
  OFFLINE = 'OFFLINE',
  ONLINE = 'ONLINE',
  BUSY = 'BUSY',
  DO_NOT_DISTURB = 'DO_NOT_DISTURB',
  AWAY = 'AWAY',
  AUTO_UPDATE = 'AUTO_UPDATE',
}

/**
 * In conjunction with `UserDoNotDisturb` and `UserStatus`, this field is aggregated into the functional state of the user's presence status. This is set by the client.  The `NONE` value for this field resets it so that it is no longer considered for presence composition.
 */
export enum UserAppearance {
  NONE = 'NONE',
  OFFLINE = 'OFFLINE',
  AWAY = 'AWAY',
  BUSY = 'BUSY',
  ONLINE = 'ONLINE',
}

/**
 * In conjunction with `UserAppearance` and `UserStatus`, this field is aggregated into the functional state of the user's presence status. This is set by the client. The `NONE` value for this field resets it so that it is no longer considered for presence composition.
 */
export enum UserDoNotDisturb {
  NONE = 'NONE',
  DO_NOT_DISTURB = 'DO_NOT_DISTURB',
}

/**
 * Custom text set by the user which is to be displayed in his/her contact card.  The maximum length is 80 characters. An empty string resets the text so that it's not longer included in presence notifications and responses.
 */
export type UserNote = string

/**
 * In conjunction with `UserAppearance` and `UserDoNotDisturb`, this field is aggregated into the functional state of the user's presence status. This is set by the client.  The `NONE` value for this field resets it so that it is no longer considered for presence composition.
 */
export enum UserStatus {
  NONE = 'NONE',
  IN_A_MEETING = 'IN_A_MEETING',
}

/**
 * The detailed state of the observed user's presence status. While the user could both be in a call and in a meeting simultaneously, the back end service will aggregate the states and provide the most relevant one.
 */
export enum Status {
  IN_A_MEETING = 'IN_A_MEETING',
  IN_A_CALL = 'IN_A_CALL',
  IN_A_CALENDAR_EVENT = 'IN_A_CALENDAR_EVENT',
}

/**
 * Information which is used on top of the current user's presence to produce presence state.
 */
export interface UserPresence {
  readonly userAppearance?: UserAppearance
  readonly userDoNotDisturb: UserDoNotDisturb
  readonly userStatus?: UserStatus
  readonly userNote?: UserNote
}

export enum UserAgentFamily {
  DESKPHONE = 'DESKPHONE',
  MOBILE = 'MOBILE',
  DESKTOP = 'DESKTOP',
  WEB = 'WEB',
}

/**
 * Describes one user agent that the user is currently online on. A user can be active on several user agents at once.
 */
export interface UserAgent {
  /**
   * A well-known name for the User-Agent. Unrecognized user agents will be reported as 'Unknown' and a family of 'DESKPHONE'.
   */
  readonly name: string
  readonly family: UserAgentFamily
}

/**
 * Channel ID provided by the Notification Service on which the presence events will be sent.
 */
export type ChannelId = string

/**
 * ID of the subscription
 */
export type SubscriptionId = string

/**
 * Describes the presence status of a user at a point in time.  The `userAppearance`, `userDoNotDisturb` and `userStatus` fields will only show up if the monitored user is the one requesting the presence.  In order to derive if an online presence is from a deskphone, a mobile phone, a web browser or a desktop application, the displayed presence must be aggregated by the client using the `appearance` and `userAgents` fields.  The `timestamp` represents the time at which the presence change was first observed.
 */
export interface Presence {
  readonly externalUserKey: ExternalUserKey
  readonly timestamp: ISO8601Timestamp
  readonly appearance: Appearance
  readonly userAgents?: readonly UserAgent[]
  readonly status?: Status
  readonly userAppearance?: UserAppearance
  readonly userDoNotDisturb?: UserDoNotDisturb
  readonly userStatus?: UserStatus
  readonly userNote?: UserNote
}

/**
 * Information representing a subscription
 */
export interface PresenceSubscription {
  readonly channelId: ChannelId
  readonly externalUserKey: ExternalUserKey
  readonly subscriptionId: SubscriptionId
}

/**
 * Contents of a presence response from the /presence endpoint
 */
export interface GetPresenceResponse {
  readonly items: readonly Presence[]
}

/**
 * Information required to create one or many presence subscription(s)
 */
export interface CreateSubscriptionsRequest {
  readonly externalUserKeys: readonly ExternalUserKey[]
  readonly channelId: ChannelId
}

/**
 * Contents of the subscription creation response
 */
export interface CreateSubscriptionsResponse {
  readonly items: readonly PresenceSubscription[]
}
type MergedTypePresenceSubscription = Pick<PresenceSubscription, 'subscriptionId' | 'channelId'> &
  Pick<UserPresence, 'userAppearance' | 'userDoNotDisturb' | 'userStatus' | 'userNote'> &
  Pick<Presence, 'appearance' | 'status'>

/**
 * The ShellPresenceService subscriptions Map is a collection of properties coming from the following types.
 *
 * The purpose of this object is to know how many elements in the app that are currently relying on the Web Socket
 * subscription.
 *
 * presence-service:
 * - Presence
 * - Subscription
 * - UserPresence
 *
 * Also the presence property holds @getgo/chameleon-core Presence
 *
 * {@link MergedTypePresenceSubscription}
 */
export interface ShellServiceSubscriptionState extends Partial<MergedTypePresenceSubscription> {
  /**
   * How many UI elements are relying on this subscription.
   */
  // eslint-disable-next-line functional/prefer-readonly-type
  subscribersCount: number

  /**
   * The chameleon design token we use for components
   *
   * See Presence from @getgo/chameleon-core
   */
  // eslint-disable-next-line functional/prefer-readonly-type
  presence?: ChameleonPresence
}

/**
 * Presence affecting state change.
 *
 * Base EventPayload for other event types.
 */
export interface NotificationChannelMessage {
  readonly externalUserKey: ExternalUserKey
}

/**
 * What is emitted from {@link ShellPresenceService.handleNotificationEvent}
 */
export interface PresenceEventPayload extends NotificationChannelMessage {
  /**
   * To help correlate event propagation, let's give an eventId from the ShellPresenceService
   * that increments automatically.
   *
   * {@link NotificationChannelEvent.eventId}
   */
  readonly eventId?: number
}

/**
 * What ShellPresenceService.emit.presenceUpdate emits.
 *
 * {@link ShellPresenceService.handleNotificationEvent}
 *
 * When there is a presence update.
 *
 * In other words, another user updates its presence status.
 *
 * This is used for keeping up to date goto-presence-indicator component
 */
export interface PresenceUpdated extends PresenceEventPayload {
  readonly presence?: ChameleonPresence
  readonly appearance?: Appearance
  readonly status?: Status
  readonly userNote?: UserPresence['userNote']
}

/**
 * What ShellPresenceService.emit.userPresenceUpdate emits.
 *
 * {@link ShellPresenceService.handleNotificationEvent}
 *
 * This event should only happen for presence events of the current user.
 */
export interface UserPresenceUpdated extends PresenceUpdated {
  readonly userDoNotDisturb?: UserPresence['userDoNotDisturb']
  readonly userAppearance?: UserPresence['userAppearance']
  readonly userStatus?: UserPresence['userStatus']
  readonly userNote?: UserPresence['userNote']
}

/**
 * What we receive from the Presence Service
 * NotificationChannelEvent<PresenceMessage>
 *
 * {@link NotificationChannelMessageType.presence}
 */
export interface PresenceMessage extends NotificationChannelMessage {
  readonly appearance: Appearance
  readonly userNote?: Presence['userNote']
  readonly status?: Status
}

/**
 * What we receive from the Presence Service
 * NotificationChannelEvent<UserPresenceMessage>
 *
 * {@link NotificationChannelMessageType.presence}
 *
 * When the current user changes its state.
 * Same shape as PresenceMessage except with UserPresence fields.
 */
export interface UserPresenceMessage extends PresenceMessage {
  readonly appearance: PresenceMessage['appearance']
  readonly userDoNotDisturb: UserPresence['userDoNotDisturb']
  readonly userAppearance: UserPresence['userAppearance']
  readonly userStatus: UserPresence['userStatus']
  readonly userNote?: UserPresence['userNote']
}

export interface GetAppearanceResponse {
  readonly items: readonly Presence[]
}

export interface PresenceSnapshot extends PresenceEventPayload {
  readonly timestamp: string
  readonly externalUserKey: string
  readonly presence: ChameleonPresence
}

export interface IShellPresenceService {
  /*
   * @deprecated You should subscribe using the `subscribe` method instead.
   */
  subscriptions: Map<ExternalUserKey, ShellServiceSubscriptionState>
  subscribe(externalUserKey: ExternalUserKey): Promise<void>
  unsubscribe(externalUserKey: ExternalUserKey): void
  getUserPresence(): Promise<UserPresence | void>
  setUserPresence(presence: Partial<UserPresence>): Promise<UserPresence | void>
  getPresenceSnapshots(externalUserKeys: ExternalUserKey): Promise<PresenceSnapshot>
}
