import { getStoredCurrentAccount } from '../../common/user-helpers'
import { getToken } from '../auth'
import { type ExternalInterface } from './external-interface'
import { type AppStateMessage, type AppStateMessagePayload } from './messages/app-state'
import { waitForShellApi, getUserPreferences } from './utils'

export const externalInterfaceAppStateDispatcher = (externalInterface: ExternalInterface) => {
  const handleRequestAppState = (payload: AppStateMessagePayload) => {
    if (payload.topic === 'get-app-state') {
      waitForShellApi().then(async shellApi => {
        externalInterface.send<AppStateMessage<'get-app-state-response'>>({
          type: 'app-state',
          payload: {
            topic: 'get-app-state-response',
            data: {
              currentAccount: shellApi.user.key ? getStoredCurrentAccount(shellApi.user.key) : null,
              userPreferences: await getUserPreferences(),
              token: getToken(),
            },
          },
        })
      })
    }
  }

  if (externalInterface.isCompanion) {
    externalInterface.addCallback('app-state', handleRequestAppState)
  }
}
