import { Environments, findConference } from '@goto/join-box-api'
import { Environment, environment } from '../environments'
import { executeCommand } from '../services/namespaces/utils'

const meetingNamespace = 'meeting-experience'
const launchConferenceCommand = 'launchConference'
const meetingConferenceType = 'meeting'

export const launchMeeting = (id: string) => {
  executeCommand(
    { namespace: meetingNamespace, commandName: launchConferenceCommand },
    { id, type: meetingConferenceType },
  )
}

export const convertEnvironment = (currentEnvironment: Environment): Environments => {
  switch (currentEnvironment) {
    case Environment.ED:
      return Environments.ED
    case Environment.PROD:
    case Environment.NIGHTLY:
      return Environments.LIVE
    case Environment.STAGING:
      return Environments.STAGE
    case Environment.RC:
      return Environments.RC
  }
}

export const validateMeetingId = async (meetingNameOrId: string, clientName: string): Promise<string | undefined> => {
  const currentEnvironment = environment().environment
  try {
    const response = await findConference(meetingNameOrId, {
      clientName,
      environment: convertEnvironment(currentEnvironment),
      retries: 3,
    })
    return response.meetingId
  } catch (e) {
    return undefined
  }
}
