import { LitElement, html, property } from 'lit-element'
import { nothing } from 'lit-html'
import { getTranslation } from '../../services/i18n/i18nUtils'
import elementStyles from './counter-badge.styles.scss'

export class CounterBadgeElement extends LitElement {
  static readonly tagName = 'goto-counter-badge'

  @property({ type: Number }) count = 0
  // When small, show small indicator without the count
  @property({ type: Boolean }) small = false

  static get styles() {
    return elementStyles
  }

  get isVisible(): boolean {
    return Number(this.count) > 0
  }

  render() {
    return this.isVisible ? html`<chameleon-badge-counter>${this.getLabel()}</chameleon-badge-counter>` : nothing
  }

  private getLabel(): string {
    const count = Number(this.count)
    if (count > 99) {
      return getTranslation(`{{badgeCount}}+`, { badgeCount: 99 })
    }

    return String(count)
  }
}

declare global {
  interface HTMLElementTagNameMap {
    readonly [CounterBadgeElement.tagName]: CounterBadgeElement
  }
}
