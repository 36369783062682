import type { CommandsNamespaceDefinition } from '@goto/shell-common'
import { getCommandsToForwardToCompanion } from '../../core/integrations-helpers'
import { getExternalInterface } from '../external-interface'
import { isFromExternalInterface } from '../external-interface/utils'

export type CommandFn<T extends readonly unknown[], U> = (...args: T) => U | undefined

/**
 * Defines possible options for the commands
 */
export interface CommandOptions {
  /**
   * capabilities are a way for handlers to add new functionality and let experiences onboard them in a conditional way,
   * allowing for easier staggered rollout of new capabilities.
   */
  readonly capabilities?: readonly string[]
  /**
   * If true, command system will send the command thru external interface if available in the context of an integration (not if is a companion app)
   */
  readonly preferCompanion?: boolean
}

let commandsNamespaceDefinitions: CommandsNamespaceDefinition[] | undefined

const shouldExecuteInCompanion = (namespace: string, commandName: string): boolean => {
  commandsNamespaceDefinitions = commandsNamespaceDefinitions || getCommandsToForwardToCompanion()
  return commandsNamespaceDefinitions.some(item => (item.namespace === namespace && (item.commandNames === undefined || item.commandNames.indexOf(commandName) !== -1)))
}

const isCommandOptions = (options: readonly string[] | CommandOptions): options is CommandOptions => !Array.isArray(options)

const extractCapabilities = (options: readonly string[] | CommandOptions) => {

  if (isCommandOptions(options)) {
    return [...(options.capabilities ?? [])]
  }

  return [...options]
}

const extractPreferCompanion = (options: readonly string[] | CommandOptions) => {
  if (isCommandOptions(options)) {
    return !!options.preferCompanion
  }
  return false
}

/*
 * Augment every command with these functions to manipulate them.
 */
export interface CommandMixin<T extends readonly unknown[], U> extends CommandFn<T, U> {
  isHandled(options?: readonly string[] | CommandOptions): boolean
  addHandler(handler: CommandFn<T, U>, options?: readonly string[] | CommandOptions): void
  removeHandler(): void
  readonly execute: CommandFn<T, U>
}

export type Command<T extends readonly unknown[] = readonly unknown[], U = unknown> = CommandMixin<T, U>

// eslint-disable-next-line functional/prefer-readonly-type
export type Commands = { [commandName: string]: Command }

const UNSET_COMMAND_FN: () => undefined = () => undefined

const equals = <T>(arr1: readonly T[], arr2: readonly T[]) =>
  arr1.length === arr2.length && arr1.every((value, index) => value === arr2[index])

export const command = <T extends readonly unknown[], U>(namespace: string, commandName: string) => {
  let supportedCapabilities: readonly string[] = []
  let commandFn: CommandFn<T, U> = UNSET_COMMAND_FN
  let isCommandSet = false
  let preferCompanion = false

  const addHandler: CommandMixin<T, U>['addHandler'] = (
    handler,
    options = {
      capabilities: [],
      preferCompanion: false,
    },
  ) => {
    commandsNamespaceDefinitions = undefined
    supportedCapabilities = extractCapabilities(options)
    preferCompanion = extractPreferCompanion(options)

    commandFn = (...args: T) => handler(...args)
    isCommandSet = !!handler
  }

  const removeHandler: CommandMixin<T, U>['removeHandler'] = () => {
    supportedCapabilities = []
    preferCompanion = false
    commandFn = UNSET_COMMAND_FN
    isCommandSet = false
  }

  const isHandled = (capabilities: readonly string[] = []) =>
    isCommandSet && (capabilities.length === 0 || equals(supportedCapabilities, capabilities))

  const isAvailable = () => getExternalInterface().available && !getExternalInterface().isCompanion

  const execute = (...args: T) => {
    if (isAvailable() && (preferCompanion || shouldExecuteInCompanion(namespace, commandName)) && !isFromExternalInterface(...args)) {
      getExternalInterface().send({
        type: 'command',
        payload: { namespace, commandName, args },
      })
      return
    }
    return commandFn(...args)
  }

  return Object.assign(execute, {
    execute,
    isHandled,
    addHandler,
    removeHandler,
  })
}
