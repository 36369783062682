import { html, property } from 'lit-element'
import type { GoToButtonProps } from './models'
import { nothing } from 'lit-html'
import { ifDefined } from 'lit-html/directives/if-defined'
import { ButtonSize, ButtonVariant } from '@getgo/chameleon-core'
import { ShellElement } from '../../common/shell-element'
import { unsafeSVG } from 'lit-html/directives/unsafe-svg'

export class GoToButton extends ShellElement implements GoToButtonProps {
  static readonly tagName = 'goto-button'

  @property({ type: Boolean }) disabled? = false
  @property({ type: String }) testIdentifier? = ''
  @property({ type: String }) variant?: ButtonVariant = 'primary'
  @property({ type: String }) labelText = ''
  @property({ type: String }) tooltipText? = ''
  @property() icon? = ''
  @property({ type: String }) size?: ButtonSize = 'medium'
  @property({ type: Boolean }) disableToolTip? = false

  connectedCallback(): void {
    super.connectedCallback()

    this.addEventListener('click', this.handleClick)

    this.unsubscribeFunctions.push(() => {
      this.removeEventListener('click', this.handleClick)
    })
  }

  private handleClick = (e: MouseEvent) => {
    if (e.target !== this.shadowRoot?.querySelector('chameleon-button')) {
      e.stopPropagation()
    }
  }

  render() {
    const iconElement = this.icon ? html`<chameleon-svg slot="start">${unsafeSVG(this.icon)}</chameleon-svg>` : nothing

    return html` <chameleon-tooltip-v2 ?hidden=${this.disableToolTip}>
      <!-- the span here is required otherwise, you canot show the tooltip when the button is disabled -->
      <span slot="trigger">
        <chameleon-button
          ?disabled=${this.disabled}
          data-test=${ifDefined(this.testIdentifier)}
          variant=${ifDefined(this.variant)}
          size=${ifDefined(this.size)}
        >
          ${iconElement} ${this.labelText}
        </chameleon-button>
      </span>
      <span slot="content">${this.tooltipText}</span>
    </chameleon-tooltip-v2>`
  }
}

declare global {
  interface HTMLElementTagNameMap {
    readonly [GoToButton.tagName]: GoToButton
  }
}
