import { environment } from '../../environments'
import { authenticatedFetch } from '../auth'
import type { ProductTrial } from '../../services/context/models'

/**
 * Makes a call to the G2M product billing API
 * NOTE: /rest/2/product API will always return 404 if the user is not on an active trial
 * @see {@link https://confluence.ops.expertcity.com/display/collaboration/Meeting+Service+REST+API+2.0#get-product-example-1}
 * @returns a parsed object of a user's G2M product billing data
 */
export const getProductTrialInfo = async (): Promise<ProductTrial | undefined> => {
  try {
    const response = await authenticatedFetch(`${environment().apiGlobal}/rest/2/product?clientName=true`, {
      headers: [['Content-Type', 'application/json']],
      credentials: undefined,
    })
    const { free, trial, product, endDate, active }: ProductTrial = await response.json()
    localStorage.setItem('productTrial', JSON.stringify({ free, trial, product, endDate, active }))
    return { free, trial, product, endDate, active } as ProductTrial
  } catch (e) {
    localStorage.removeItem('productTrial')
    //silently fail as most users will throw a 404 when they are not on a trial
  }
}
