import type { ExtensionConfig, ShellExtension, ShellExtensionCustomElements } from '@goto/shell-common'
import { loadExtensionScript } from './utils/loader'
import type { GotoRegisteredApplications } from '../core/models'
import type { RegisterApplicationConfig } from 'single-spa'
import { registerApplication } from 'single-spa'
import type { CustomProps } from '../common'
import { getShellLogger } from '../common/logger'
import { supportsCustomElements } from './utils/type-checking'
import { getStandaloneExtensionConfigForCurrentLocation } from './utils/standalone-filter'
import { getUnguardedRoutes } from '../core/helpers/unguarded-routes'
import { getFeatureFlagValue } from '../services/feature-flags'
import { type BlockedExtensionIdsFlag } from '../core/hooks/blocked-extensions-banner'
import { FeatureFlagsVariations } from '../services/feature-flags/models'
import { addExtensionWithDependencies, isPathMatchingRoute } from './utils'
import { emitExtensionReadyEvent } from '../services/shell-namespace'
import { type ExtensionConfigProvider, type ExtensionsManager } from './extensions-manager-intf'
import { applyExtensionConfigsTransformers } from './utils/extensions-config-transformers'

type ResolveType = () => void
type RejectType = () => void

type PendingPromises = {
  resolves: ResolveType[]
  rejects: RejectType[]
}

const DEFAULT_PENDING_PROMISES: PendingPromises = { resolves: [], rejects: [] }
/**
 * Class which manages extensions life cycle for the shell
 */
class ExtensionsManagerImpl implements ExtensionsManager, ExtensionConfigProvider {
  private readonly extensionConfigs = new Array<ExtensionConfig>()
  private readonly extensions = new Map<ExtensionConfig, ShellExtension>()
  private readonly onExtensionReadyResolvers = new Map<string, PendingPromises>()

  private readonly registeredApplications: GotoRegisteredApplications = new Map<
    string,
    RegisterApplicationConfig<CustomProps>
  >()

  public getExtensionConfigs() {
    return this.extensionConfigs
  }

  public getBlockedExtensionIds() {
    const blockedExtensionIds = new Set<string>()
    const blockedExtensionsFromFlags = getFeatureFlagValue<BlockedExtensionIdsFlag>(
      FeatureFlagsVariations.SHELL_BLOCKED_EXTENSION_IDS,
    ) ?? { block: [] }

    this.extensionConfigs.forEach(extensionConfig => {
      if (blockedExtensionsFromFlags.block.includes(extensionConfig.id)) {
        blockedExtensionIds.add(extensionConfig.id)
      }
    })

    return blockedExtensionIds
  }

  public initializeExtensions(extensions: Map<ExtensionConfig, ShellExtension>): Promise<void> {
    const initializationPromises: Promise<void>[] = []
    extensions.forEach((extension, config) => initializationPromises.push(this.initializeExtension(extension, config)))
    return new Promise((resolve, reject) => {
      Promise.allSettled(initializationPromises)
        .then(() => {
          resolve()
        })
        .catch(reason => reject(reason))
    })
  }

  public addExtensions(extensionConfigs: readonly ExtensionConfig[]): void {
    this.extensionConfigs.push(...extensionConfigs)
  }

  public removeExtensionConfig(id: string): void {
    const extensionIdx = this.extensionConfigs.findIndex(config => config.id === id)
    if (extensionIdx > -1) {
      this.extensionConfigs.splice(extensionIdx, 1)
    }
  }

  public async loadAndInitializeExtensions(extensionIds?: string[]): Promise<void> {
    let extensionConfigsToTransform: ExtensionConfig[] = []
    if (extensionIds?.length) {
      extensionConfigsToTransform = this.getExtensionConfigsFromIds(extensionIds)
    } else {
      extensionConfigsToTransform = this.getExtensionConfigs()
    }

    const transformedExtensionConfigs = applyExtensionConfigsTransformers(extensionConfigsToTransform)

    const extensionConfigsToLoad = this.filterLoadedExtensionConfigs(transformedExtensionConfigs)

    const loadedExtensionsToInitialize = await this.internalLoadExtensions(extensionConfigsToLoad)

    await this.initializeExtensions(loadedExtensionsToInitialize)
  }

  private getExtensionConfigsFromIds(extensionIds: string[]) {
    const matchingExtensionConfigs = this.getExtensionConfigs().filter(extensionConfig =>
      extensionIds.some(extensionId => extensionId === extensionConfig.id),
    )
    //add dependencies for given extensions
    const extensions = new Set<string>()
    matchingExtensionConfigs.forEach(extensionConfig => {
      addExtensionWithDependencies(extensions, this.getExtensionConfigs(), extensionConfig)
    })

    return this.getExtensionConfigs().filter(extensionConfig => extensions.has(extensionConfig.id))
  }

  private filterLoadedExtensionConfigs(extensionConfigs: readonly ExtensionConfig[]) {
    return extensionConfigs.filter(
      extensionConfig => !this.getLoadedExtensionConfigs().some(config => config.id === extensionConfig.id),
    )
  }

  public getExtensions(): readonly ShellExtension[] {
    return Array.from(this.extensions.values())
  }

  public getExtensionById(extensionId: string): ShellExtension | undefined {
    return this.getExtensions().find(extension => extension.id === extensionId)
  }

  public getLoadedExtensionConfigs(): readonly ExtensionConfig[] {
    return Array.from(this.extensions.keys())
  }

  public getUnguardedRoutes(): readonly string[] {
    return getUnguardedRoutes(this.extensionConfigs)
  }

  public registerApplication(name: string, application: RegisterApplicationConfig<CustomProps>) {
    try {
      registerApplication(application)
      this.registeredApplications.set(name, application)
    } catch (e) {
      getShellLogger().error(`Cannot register the ${name} extension`, e)
    }
  }

  public getRegisteredApplications() {
    return this.registeredApplications
  }

  public isExtensionReady(extensionId: string): boolean {
    return !!this.getExtensions().find(extension => extension.id === extensionId)
  }

  public onExtensionReady(extensionId: string): Promise<void> {
    return new Promise((resolve, reject) => {
      if (this.isExtensionReady(extensionId)) {
        resolve()
      } else {
        const promises = this.onExtensionReadyResolvers.get(extensionId) ?? DEFAULT_PENDING_PROMISES
        promises.resolves.push(resolve)
        promises.rejects.push(reject)

        this.onExtensionReadyResolvers.set(extensionId, promises)
      }
    })
  }

  public isStandaloneRoute(route: string) {
    return !!getStandaloneExtensionConfigForCurrentLocation(this.extensionConfigs, route)
  }

  public isValidShellModuleRoute(route: string) {
    const extensions = Array.from(this.extensions.values())
    return extensions.some(extension =>
      extension
        .getModules()
        .some(shellModule => shellModule.routes.some(routeMatcher => isPathMatchingRoute(route, routeMatcher))),
    )
  }

  private resolveExtensionReadyResolvers(extensionId: string) {
    const promises = this.onExtensionReadyResolvers.get(extensionId) ?? DEFAULT_PENDING_PROMISES
    promises.resolves.forEach(resolve => resolve())
    this.onExtensionReadyResolvers.delete(extensionId)
  }

  private resolveExtensionReadyRejecters(extensionId: string) {
    const promises = this.onExtensionReadyResolvers.get(extensionId) ?? DEFAULT_PENDING_PROMISES
    promises.rejects.forEach(reject => reject())
    this.onExtensionReadyResolvers.delete(extensionId)
  }

  private initializeExtension(extension: ShellExtension, config: ExtensionConfig): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const rejectWithFailure = (reason: unknown, message: string) => {
        this.extensions.delete(config)
        getShellLogger().error(message, reason)
        reject(reason)
      }
      extension
        .initialize(config)
        .then(() =>
          extension.registerNamespaces().catch(reason => {
            rejectWithFailure(reason, `${extension.id} failed when registering namespace`)
          }),
        )
        .then(() => {
          if (supportsCustomElements(extension)) {
            try {
              ; (extension as ShellExtensionCustomElements).registerCustomElements()
            } catch (e) {
              rejectWithFailure(e, `${extension.id} failed when registering custom elements`)
            }
          }
        })
        .then(() => {
          this.resolveExtensionReadyResolvers(extension.id)
          emitExtensionReadyEvent({ extensionId: extension.id })
          resolve()
        })
        .catch(reason => {
          this.resolveExtensionReadyRejecters(extension.id)
          rejectWithFailure(reason, `${extension.id} failed to initialize`)
        })
    })
  }

  private async internalLoadExtensions(
    extensionConfigs: readonly ExtensionConfig[],
  ): Promise<Map<ExtensionConfig, ShellExtension>> {
    const loadedExtensionsToInitialize = new Map<ExtensionConfig, ShellExtension>()

    const loadExtension = async (config: ExtensionConfig): Promise<boolean> => {
      if (this.extensions.has(config)) {
        return true // Extension already loaded
      }

      const extension = await loadExtensionScript(config)
      if (extension) {
        this.extensions.set(config, extension)
        loadedExtensionsToInitialize.set(config, extension)
        if (extension.id !== config.id) {
          getShellLogger().warn(
            `The ${config.id} id from the extension config does not match the ${extension.id} id provided by the extension script`,
          )
        }
        return true
      }
      return false
    }

    for (const config of extensionConfigs) {
      const mandatoryDependencies = config.dependencies ?? []
      const optionalDependencies = config.optionalDependencies ?? []

      const mandatoryDependenciesLoaded = await Promise.allSettled(
        mandatoryDependencies.map(async dependencyId => {
          const dependency = extensionConfigs.find(extensionConfig => extensionConfig.id === dependencyId)
          return dependency ? await loadExtension(dependency) : false
        }),
      )

      const hasFailedMandatoryDependencies = mandatoryDependenciesLoaded.some(
        result => result.status === 'rejected' || (result.status === 'fulfilled' && !result.value),
      )

      if (hasFailedMandatoryDependencies) {
        getShellLogger().error(`Failed to load mandatory dependencies for ${config.id}`)
        continue
      }

      await Promise.allSettled(
        optionalDependencies.map(async dependencyId => {
          const dependency = extensionConfigs.find(extensionConfig => extensionConfig.id === dependencyId)
          if (dependency) {
            await loadExtension(dependency)
          }
        }),
      )

      await loadExtension(config)
    }
    return loadedExtensionsToInitialize
  }
}

const extensionsManagerInstance = new ExtensionsManagerImpl()

export const getExtensionsManager = (): ExtensionsManager => extensionsManagerInstance
