import { AuthWeb } from './authentication.web'
import { getExternalInterface } from '../external-interface'
import { getToken } from './token'
import { getStoreCurrentRoute } from '../../common/store-current-route'
import { getInflightRequest } from './utils'
import { navigateToUrl } from '../../common/helpers'
import { ROOT_ROUTE } from '../../common/routes'
export class AuthIntegration extends AuthWeb {
  constructor() {
    super()

    const handleConnection = (isConnected: boolean) => {
      if (isConnected && getToken()) {
        const url = getInflightRequest() ?? getStoreCurrentRoute() ?? ROOT_ROUTE
        navigateToUrl(url)
      }
    }

    handleConnection(getExternalInterface().available)

    getExternalInterface().addConnectionCallback(e => handleConnection(e.connectionStatus === 'connected'))
  }

  async logout() {
    getExternalInterface().send({ type: 'auth-logout', payload: {} })
    this.removeTokens()
    this.navigateToLandingPage()
  }

  async login() {
    getExternalInterface().send({ type: 'request-auth-token', payload: {} })
  }

  async authProcess() {
    this.navigateToLandingPage()
  }
}
