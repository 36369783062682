import { html } from 'lit-element'
import { ShellElement } from '../../common/shell-element'
import { reloadPage } from '../../common/dom-helpers'
import blockedExtensionsStyles from './blocked-extensions-banner.styles.scss'
import { getTranslation } from '../../services/i18n/i18nUtils'

export class BlockedExtensionsBanner extends ShellElement {
  static readonly tagName = 'goto-blocked-extensions-banner'

  static get styles() {
    return blockedExtensionsStyles
  }

  render() {
    return html` <chameleon-banner variant="${'danger' as any}">
      <span>
        ${getTranslation(
          `Some parts of GoTo didn't load correctly. We're working on fixing it, so wait a little while before you try to reload.`,
        )}
      </span>
      <a slot="action" href="#" @click=${reloadPage}>${getTranslation('Reload')}</a>
    </chameleon-banner>`
  }
}

declare global {
  interface HTMLElementTagNameMap {
    readonly [BlockedExtensionsBanner.tagName]: BlockedExtensionsBanner
  }
}
